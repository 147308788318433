import { BaseProjectInfo, DetailsResponse, ImportResponse, ImportStorageRequest, ImportStorageResponse, ProjectInfo, StatusResponse, StorageSpec, TableResponse, TimeSeriesResponse, WindTurbineSpec, ImportWindResponse, ImportWindRequest, UserData, OstResponse, DimensionResponse, UserEmailNotification, ComponentOperationResponse, ReportResponse, SystemSizerLimits, SystemSizerTableResponse, ExcelResponse } from './api'
import {ComplexCostTableDto, InputsDto} from 'services/inputs'
import download from 'downloadjs'
import {parse} from 'shared/content-disposition'
import {Err, Ok, Result} from 'shared/Result'
import { OstDimension } from '../components/project/results'
import { observable } from 'mobx'
import { UserStorage, UserStorageClass } from '../components/userLibrary/UserStorage'
import { CustomerTestimonial } from '../components/project/pages/Results/ResultReport'
import { ExportStateProps } from '../components/project/pages/Results/ResultExport'


const JSON_HEADER = {'Content-Type': 'application/json','charset': 'utf-8'}
const TEXT_HEADER = {'Content-Type': 'text/plain'}

const GET: RequestInit = {
    method: 'GET',
    headers: {...JSON_HEADER},
}

const POST: RequestInit = {
    method: 'POST',
    headers: {...JSON_HEADER},
    body: '',
}

const PUT: RequestInit = {
    method: 'PUT',
    headers: {...JSON_HEADER},
    body: '',
}

const PATCH: RequestInit = {
    method: 'PATCH',
    headers: {...JSON_HEADER},
}

const DELETE: RequestInit = {
    method: 'DELETE',
}


async function check<T>(response: Response): Promise<Result<T>> {
    const data = await response.json()
    if (!response.ok) {
        const error = data as ErrorDetails
        const message = `[${error.status}] ${error.title}\n\n${error.detail}`
        console.error(message)
        return Err({name: response.statusText, message})
    }
    return Ok(data as T)
}

async function checkUnsafe<T>(response: Response): Promise<T> {
    const result = await check<T>(response)
    if (result.ok) {return result.value} else {throw result.error}
}


export class Service {
    constructor(authenticationHandler?: () => void) {
        this.authenticationHandler = authenticationHandler
    }

    // called on `401:Unauthorized`
    authenticationHandler?: () => void

    // credentials to refresh token
    name: string = ''
    password: string = ''

    // current session token
    token: string = ''
    clearToken() {this.token = ''}
    setToken(v: string) {this.token = v}

    //userinfo
    email: string = ""
    defaultCurrency: string = ""
    defaultDecimalPoint: boolean = false
    defaultDateFormat: number = 0
    showBaseYearAsYearZero: boolean = false

    // fetch + authentication
    async fetch(input: RequestInfo, init?: RequestInit, failOn401?: boolean): Promise<Response> {
        if (!init) init = {}
        const headers = new Headers(init?.headers)
        headers.set('Authorization', `Bearer ${this.token}`)

        try {
            const response = await fetch(input, {...init, headers})
            if (response.status === 401) {
                if (!failOn401) {
                    // try to refresh token
                    const loginResult = await this.login(this.name, this.password)
                    if (!loginResult.success) {
                        this.clearToken()
                        this.authenticationHandler?.()
                    } else {
                        return await this.fetch(input, init, true)
                    }
                }
                this.clearToken()
                this.authenticationHandler?.()
            }            
            return response
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    async login(name: string, password: string): Promise<LoginResult> {
        // save `name` and `password` to refresh token later
        this.name = name
        this.password = password

        // internal user names
        const internal = ['guest', 'reports', 'samples']
        const scheme = internal.includes(name) ? 'internal' : 'homer'

        try {
            const data: TokenRequest = {scheme, name, password}
            const response = await fetch('/api/auth/token', {...POST, body: JSON.stringify(data)})

            // handle `400:BadRequest` error
            if (response.status === 400) {
                const json = await response.json()
                const result = json as TokenResponse
                return {success: false, errors: result.errors}
            }

            // handle other errors
            if (!response.ok) {
                const body = await response.text()
                console.error(body)
                return { success: false, errors: ['The system is unavailable. If this error persists, please send us a message at support@homerenergy.com.']}
            }

            const json = await response.json()
            const result = json as TokenResponse
            this.setToken(result.token)

            //get user data
            var userData = await this.readUserData()
            this.defaultCurrency = userData.defaultCurrency
            this.defaultDateFormat = userData.defaultDateFormat
            this.defaultDecimalPoint = userData.defaultDecimalPoint
            this.showBaseYearAsYearZero = userData.showBaseYearAsYearZero

            return {success: true, errors: []}
        } catch (error) {
            // handle network errors
            console.error(error)
            return {success: false, errors: [error.message]}
        }
    }

    // API

    async list(): Promise<ProjectInfo[]> {
        const r = await this.fetch(`/api/project`, {...GET})
        return await checkUnsafe(r)
    }

    async create(): Promise<ProjectInfo> {
        const r = await this.fetch(`/api/project`, {...POST, body: '{}'})
        return await checkUnsafe(r)
    }

    async read(id: string): Promise<ProjectInfo> {
        const r = await this.fetch(`/api/project/${id}`, {...GET})
        return await checkUnsafe(r)
    }

    async update(id: string, x: BaseProjectInfo): Promise<void> {
        await this.fetch(`/api/project/${id}`, {...PUT, body: JSON.stringify(x)})
    }

    async updateLock(id: string, status: boolean, lockId: string, forceLock = false): Promise<boolean> {
        const request = { status: status, lockId: lockId, forceLock: forceLock}
        const r = await this.fetch(`/api/project/${id}/lock`, { ...POST, body: JSON.stringify(request) })
        return r.status === 200
    }

    async delete(id: string): Promise<void> {
        await this.fetch(`/api/project/${id}`, {...DELETE})
    }

    async duplicate(id: string): Promise<ProjectInfo> {
        const r = await this.fetch(`/api/project/${id}/duplicate`, {...POST, body: '{}'})
        return await checkUnsafe(r)
    }

    async readRawData(id: string): Promise<string> {
        const r = await this.fetch(`/api/project/${id}/input`, {...GET, headers: {...TEXT_HEADER}})
        const t = await r.text()
        console.log(t)
        return t
    }

    async updateRawData(id: string, data: string): Promise<number> {
        const r = await this.fetch(`/api/project/${id}/input`, {...PUT, headers: {...TEXT_HEADER}, body: data})
        return r.status // no content
    }

    async patchRawData(id: string, hashExistingData: string, hashNewData: string, patch: string): Promise<number> {
        const request = { hashExistingData, hashNewData, patch}
        const r = await this.fetch(`/api/project/${id}/input`, {...PATCH, body: JSON.stringify(request)})
        // possible errors: 409 - conflict; 404 - not found; 500 - internal server error
        return r.status // no content
    }

    async readUserData(): Promise<UserData> {
        const r = await this.fetch(`/api/project/user`, { ...GET })
        return await checkUnsafe(r)
    }

    async updateUserDefaultCurrency(defaultCurrency: string): Promise<boolean> {
        var data = JSON.stringify(defaultCurrency)
        const r = await this.fetch(`/api/project/user/currency`, { ...PUT, headers: { ...JSON_HEADER }, body: data })
        this.defaultCurrency = defaultCurrency
        return r.status === 204 // no content
    }

    async updateUserDefaultDateFormat(defaultDateFormat: number): Promise<boolean> {
        var data = JSON.stringify(defaultDateFormat)
        const r = await this.fetch(`/api/project/user/dateFormat`, { ...PUT, headers: { ...JSON_HEADER }, body: data })
        this.defaultDateFormat = defaultDateFormat
        return r.status === 204 // no content
    }

    async updateUserDefaultDecimalPoint(defaultDecimalPoint: boolean): Promise<boolean> {
        var data = JSON.stringify(defaultDecimalPoint)
        const r = await this.fetch(`/api/project/user/decimalPoint`, { ...PUT, headers: { ...JSON_HEADER }, body: data })
        this.defaultDecimalPoint = defaultDecimalPoint
        return r.status === 204 // no content
    }

    async updateUserShowBaseYearAsYearZero(showBaseYearAsYearZero: boolean): Promise<boolean> {
        var data = JSON.stringify(showBaseYearAsYearZero)
        const r = await this.fetch(`/api/project/user/showBaseYearAsYearZero`, { ...PUT, headers: { ...JSON_HEADER }, body: data })
        this.showBaseYearAsYearZero = showBaseYearAsYearZero
        return r.status === 204 // no content
    }

    async import(content?: string, year?: number): Promise<ImportResponse> {
        let url = '/api/info/import'
        if (year)
            url += `?year=${year}`
        const r = await this.fetch(url, {
            ...POST,
            headers: {'Content-Type': 'text/plain'},
            body: content,
        })
        return await checkUnsafe(r)
    }

    async importStorage(content: string): Promise<ImportStorageResponse> {
        const url = '/api/info/import/storage'
        const request: ImportStorageRequest = {content}
        const r = await this.fetch(url, {...POST, body: JSON.stringify(request)})
        return await checkUnsafe(r)
    }

    async importWind(content: string): Promise<ImportWindResponse> {
        const url = '/api/info/import/windTurbine'
        const request: ImportWindRequest = { content }
        const r = await this.fetch(url, { ...POST, body: JSON.stringify(request) })
        return await checkUnsafe(r)
    }

    async getResultTable(id: string, index?: number, kind?: 'all' | 'group', baseCaseIndex?: number): Promise<TableResponse> {
        let url = `/api/project/${id}/result/table`
        if (index !== undefined) {
            url += `/${index}`
            if (kind !== undefined) {
                url += `/${kind}`
            }
            if (baseCaseIndex != undefined)
                url += `/${baseCaseIndex}`
        }
        const r = await this.fetch(url, {...POST})
        return await checkUnsafe(r)
    }

    async getOstDimensions(id: string): Promise<DimensionResponse> {
        let url = `/api/project/${id}/result/ost`
        const r = await this.fetch(url, { ...GET })
        return await checkUnsafe(r)
    }

    async getOstResult(id: string, x: OstDimension[]): Promise<OstResponse> {
        let url = `/api/project/${id}/result/ost`
        const r = await this.fetch(url, { ...POST, body: JSON.stringify(x) })
        return await checkUnsafe(r)
    }

    async getDetails(id: string, resultId: string): Promise<Result<DetailsResponse>> {
        let url = `/api/project/${id}/result/details/${resultId}`
        const r = await this.fetch(url, {...POST})
        return await check(r)
    }

    async getTimeSeries(id: string, resultId: string, year: number): Promise<TimeSeriesResponse> {
        let url = `/api/project/${id}/result/details/${resultId}/timeseries/${year}`
        const r = await this.fetch(url, {...POST})
        return await checkUnsafe(r)
    }

    async exportTimeSeries(id: string, resultId: string, year: number): Promise<string> {
        const url = `/api/project/${id}/result/export/${resultId}/timeseries/${year}`
        const r = await this.fetch(url, {...POST})
        const t = await r.text()
        console.log(t)
        return t
    }

    async exportMultiYearTimeSeries(id: string, resultId: string): Promise<string> {
        const url = `/api/project/${id}/result/export/${resultId}/timeseries`
        const r = await this.fetch(url, { ...POST })
        const t = await r.text()
        console.log(t)
        return t
    }

    async exportMultiYearResultsExcel(id: string, resultId: string, items: ExportStateProps): Promise<ExcelResponse> {
        const url = `/api/project/${id}/result/export/${resultId}/excel`
        const r = await this.fetch(url, { ...POST, body: JSON.stringify(items) })
        if (r.status === 204)
            return { succeded: false }
        else {
            const excelData = await r.blob()
            return { succeded: true, data: excelData, fileName: getFileName(r.headers) ?? 'Result.xlsx' }
        }
    }

    async exportCashFlow(id: string, resultId: string): Promise<string> {
        const url = `/api/project/${id}/result/export/${resultId}/cashflow`
        const r = await this.fetch(url, {...POST})
        const t = await r.text()
        console.log(t)
        return t
    }

    async getStatus(id: string): Promise<StatusResponse> {
        let url = `/api/project/${id}/result/status`
        const r = await this.fetch(url, {...POST})
        return await checkUnsafe(r)
    }

    async getProStatus(id: string): Promise<StatusResponse> {
        let url = `/api/proproject/${id}/result/status`
        const r = await this.fetch(url, { ...POST })
        return await checkUnsafe(r)
    }

    async calculate(id: string): Promise<StatusResponse> {
        let url = `/api/project/${id}/result/calculate`
        const r = await this.fetch(url, {...POST})
        return await checkUnsafe(r)
    }

    async cancelCalculation(id: string): Promise<boolean> {
        let url = `/api/project/${id}/result/stop`
        const r = await this.fetch(url, { ...POST })
        return r.status == 200
    }

    async getStatusDetails(id: string, resultId: string): Promise<StatusResponse> {
        let url = `/api/project/${id}/result/details/status/${resultId}`
        const r = await this.fetch(url, {...POST})
        return await checkUnsafe(r)
    }

    async calculateDetails(id: string, resultId: string): Promise<StatusResponse> {
        let url = `/api/project/${id}/result/details/calculate/${resultId}`
        const r = await this.fetch(url, {...POST})
        return await checkUnsafe(r)
    }

    async setUserEmailNotification(problemId: string, userEmail: UserEmailNotification): Promise<boolean> {
        var data = JSON.stringify(userEmail)
        const r = await this.fetch(`/api/project/${problemId}/result/email`, { ...PUT, headers: { ...JSON_HEADER }, body: data })        
        return r.ok // no content
    }

    async cancelDetailsCalculation(id: string, resultId: string): Promise<boolean> {
        let url = `/api/project/${id}/result/details/stop/${resultId}`
        const r = await this.fetch(url, { ...POST })        
        return r.status == 200
    }

    async deleteUserEmailNotification(problemId: string): Promise<boolean> {        
        const r = await this.fetch(`/api/project/${problemId}/result/email`, { ...DELETE })
        return r.ok // no content
    }

    async downloadReportFile(id: string, resultId: string, sensitivity: number, optimization: number, request: ResultReportRequest): Promise<ReportResponse> {
        let url = `/api/project/${id}/result/${resultId}/reportFile/${sensitivity}/${optimization}`
        const r = await this.fetch(url, { ...POST, body: JSON.stringify(request) })
        if (r.status === 204)
            return { succeded: false }
        else {
            const reportData = await r.blob()
            return { succeded: true, data: reportData, fileName: getFileName(r.headers) ?? 'Result.pdf' }
        }
    }

    async downloadHomerFile(id: string) {
        let url = `/api/project/${id}/result/file`
        const r = await this.fetch(url, {...POST})
        const data = await r.blob()
        const filename = getFileName(r.headers) ?? 'Project.hfront'
        download(data, filename, 'application/octet-stream')
    }

    async reportIssue(id: string, description?: string) {
        const request: ShareProject = {description}
        const r = await this.fetch(`/api/project/${id}/report`, {...POST, body: JSON.stringify(request)})
        const t = await r.text()
        console.log(t)
    }

    async shareProject(id: string, email: string, description?: string): Promise<number> {
        const request: ReportRequest = { email, description }
        const r = await this.fetch(`/api/project/${id}/share`, { ...POST, body: JSON.stringify(request) })
        const t = await r.status
        console.log(t)
        return t
    }

    storages?: string[]
    storageSpecs?: StorageSpec[]
    userStorageSpecs?: StorageSpec[]
    windSpecs?: WindTurbineSpec[]

    @observable userStorage: UserStorage = new UserStorage()

    async listStorages(): Promise<string[]> {
        if (!this.storages) {
            const url = `/api/info/storages`
            const r = await this.fetch(url, {...POST})
            const result = await checkUnsafe<ListComponentResponse>(r)
            this.storages = result.data
        }
        return this.storages
    }

    async listStorageSpecs(): Promise<StorageSpec[]> {
        if (!this.storageSpecs) {
            const url = `/api/info/storages/specs`
            const r = await this.fetch(url, {...POST})
            const result = await checkUnsafe<ListStorageSpecResponse>(r)
            this.storageSpecs = result.data
        }
        return this.storageSpecs
    }

    async listWindTurbineSpecs(): Promise<WindTurbineSpec[]> {
        if (!this.windSpecs) {
            const url = `/api/info/winds/specs`
            const r = await this.fetch(url, {...POST})
            const result = await checkUnsafe<ListWindSpecResponse>(r)
            this.windSpecs = result.data
        }
        return this.windSpecs
    }

    solarCosts?: ComplexCostTableDto

    async getSolarCostItems(name?: string): Promise<ComplexCostTableDto> {
        if (!this.solarCosts) {
            const url = `/api/info/cost/solar`
            const content = JSON.stringify({name})
            const r = await this.fetch(url, {...POST, body: content})
            const result = await checkUnsafe<GetCostItemsResponse>(r)
            this.solarCosts = result.data
        }
        return this.solarCosts
    }

    storageCosts?: ComplexCostTableDto

    async getStorageCostItems(name?: string): Promise<ComplexCostTableDto> {
        if (!this.storageCosts) {
            const url = `/api/info/cost/storage`
            const content = JSON.stringify({name})
            const r = await this.fetch(url, {...POST, body: content})
            const result = await checkUnsafe<GetCostItemsResponse>(r)
            this.storageCosts = result.data
        }
        return this.storageCosts
    }

    samples?: SampleDto[]

    async listSamples(): Promise<SampleDto[]> {
        if (!this.samples) {
            const url = `/api/sample/list`
            const r = await this.fetch(url, {...GET})
            const result = await checkUnsafe<SampleDto[]>(r)
            this.samples = result
        }
        return this.samples
    }

    async cloneSample(id: string): Promise<ProjectInfo> {
        const r = await this.fetch(`/api/sample/clone/${id}`, {...POST, body: '{}'})
        return await checkUnsafe(r)
    }

    async addStorageFromSample(): Promise<ComponentOperationResponse> {
        const url = `/api/userLibrary/storage/sample`
        const r = await this.fetch(url, { ...POST })
        this.userStorageSpecs = undefined
        return await checkUnsafe<ComponentOperationResponse>(r)
    }

    async importStorageFromFile(data: string): Promise<ComponentOperationResponse> {
        const url = `/api/userLibrary/storage/import`
        const r = await this.fetch(url, { ...POST, body: JSON.stringify({ data }) })
        this.userStorageSpecs = undefined
        return await checkUnsafe<ComponentOperationResponse>(r)
    }

    async duplicateUserStorage(data: string): Promise<ComponentOperationResponse> {
        const url = `/api/userLibrary/storage/duplicate`
        const r = await this.fetch(url, { ...POST, body: JSON.stringify({ data }) })
        this.userStorageSpecs = undefined
        return await checkUnsafe<ComponentOperationResponse>(r)
    }

    async deleteUserStorage(data: string): Promise<ComponentOperationResponse> {
        const url = `/api/userLibrary/storage/delete`
        const r = await this.fetch(url, { ...POST, body: JSON.stringify({ data }) })
        this.userStorageSpecs = undefined
        return await checkUnsafe<ComponentOperationResponse>(r)
    }

    async updateUserStorage(data: StorageSpec): Promise<ComponentOperationResponse> {
        const url = `/api/userLibrary/storage/update`
        const r = await this.fetch(url, { ...POST, body: JSON.stringify({ data }) })
        this.userStorageSpecs = undefined
        this.userStorage.specs = null
        return await checkUnsafe<ComponentOperationResponse>(r)
    }

    async downloadUserStorage(id: string) {
        const url = `/api/userLibrary/storage/download`
        const r = await this.fetch(url, { ...POST, body: JSON.stringify({ id }) })
        const data = await r.blob()
        const filename = getFileName(r.headers) ?? 'Storage.xml'
        download(data, filename, 'application/octet-stream')
    }

    async listUserStorageSpecs(): Promise<StorageSpec[]> {
        if (!this.userStorageSpecs) {
            const url = `/api/userLibrary/storages/specs`
            const r = await this.fetch(url, { ...POST })
            if (r.status != 200) return []
            const result = await checkUnsafe<ListStorageSpecResponse>(r)
            this.userStorageSpecs = result.data
        }
        return this.userStorageSpecs
    }

    async getUserStorage(id: string) {
        if (this.userStorage.specs == undefined || this.userStorage.specs == null || this.userStorage.specs.name == "" || this.userStorage.specs.id != id) {
            if (!this.userStorageSpecs) {
                await this.listUserStorageSpecs()
            }
            var spec = this.userStorageSpecs!.filter(x => x.id == id)[0]
            this.userStorage.specs = new UserStorageClass()
            this.userStorage.specs.initialize(spec)
        }
        return this.userStorage.specs
    }

    async calculatePro(id: string, limits: SystemSizerLimits): Promise<StatusResponse> {
        let url = `/api/proproject/${id}/result/calculate`
        const r = await this.fetch(url, { ...POST, body: JSON.stringify(limits) })
        return await checkUnsafe(r)
    }

    async downloadHomerProFile(id: string, limits: SystemSizerLimits) {
        let url = `/api/proproject/${id}/result/file`
        const r = await this.fetch(url, { ...POST, body: JSON.stringify(limits) })
        const data = await r.blob()
        const filename = getFileName(r.headers) ?? 'Project.homer'
        download(data, filename, 'application/octet-stream')
    }

    async getProResultTable(id: string): Promise<SystemSizerTableResponse> {
        let url = `/api/proproject/${id}/result/table`
        const r = await this.fetch(url, { ...POST })
        return await checkUnsafe(r)
    }
}


export interface TokenRequest {
    scheme?: string
    name: string
    password: string
}

export interface TokenResponse {
    success: boolean
    errors: string[]
    token: string
}

export interface LoginResult {
    success: boolean
    errors: string[]
}


interface ErrorDetails {
    type?: string
    title?: string
    status?: number
    detail?: string
}


interface ListComponentResponse {data: string[]}

interface ListStorageSpecResponse {data: StorageSpec[]}

interface ListWindSpecResponse {data: WindTurbineSpec[]}

interface GetCostItemsResponse {data: ComplexCostTableDto}

interface ReportRequest {
    email: string
    description?: string
}

export interface ResultReportRequest {
    isClientProposal: boolean
    //Client Proposal
    clientName: string
    clientTitle: string
    projectAddress: string
    userName: string
    userTitle: string
    userEmail: string
    userPhone: string
    companyName: string
    companyDescription: string
    companyLogo: string
    customerTestimonials: CustomerTestimonial[]
    //Engineer Detail
    projectDescription: string
    customLogo: string
    paperSize: string
}

interface ShareProject { description?: string }


export interface SampleDto {
    id: string
    name: string
}


function getFileName(headers: Headers): string | undefined {
    const value = headers.get('content-disposition')
    if (!value) return
    const result = parse(value)
    return result.parameters['filename'] as string | undefined
}
