import { BusKind, Project } from 'components/project/model'
import { MultiYearItem, MultiYearVar } from 'components/project/model/MultiYearVar'
import { SensitivityItem, SensitivityVar } from 'components/project/model/SensitivityVar'
import { ComplexCostItem, ComplexCostTable, CostSensitivityItem, CostTable, CostTableType, CostUnit, SimpleCostTable } from 'components/project/model/CostTable'
import { eq } from 'utils'
import { StorageSpec, WindTurbineSpec } from 'services/api'
import { SizeItem, Sizing } from 'components/project/model/Sizing'
import { Resource, ResourceKind } from 'components/project/model/Resource'
import { Inverter } from 'components/project/model/Inverter'
import { Solar } from 'components/project/model/Solar'
import { TimeSeries } from 'components/project/model/TimeSeries'
import { BonusDepreciation, Incentive, InvestmentTaxCredit, Macrs, ProductionBased } from 'components/project/model/Incentive'
import { Storage, StorageKind } from 'components/project/model/Storage'
import { Contract, ContractEnergyPriceKind, ContractObligationKind, ContractObligationRequirement, DayKind, ShortagePenaltyKind, SystemSizerLimitsClass, SystemSizerResultsColKind } from 'components/project/model/Contract'
import { CapacityMarket, EnergyPriceKind, MonthlyVar, MonthlyVarItem, PriceUnit, RaEvent, RaEventKind } from 'components/project/model/CapacityMarket'
import { EnergyMarket, EnergyMarketKind } from 'components/project/model/EnergyMarket'
import { Wind, WindTurbineKind } from 'components/project/model/Wind'
import { MultiYearTimeSeries } from '../components/project/model/MultiYearTimeSeries'
import { RegulationMarket } from '../components/project/model/RegulationMarket'
import { defaultSchedule, RateSchedule, ScheduleRate } from '../components/project/model/Schedule'
import { Deferment } from '../components/project/model/Deferment'

export interface InputsDto {
    fileVersion?: string | undefined
    version: number | undefined
    name: string
    author: string
    notes: string
    location: LocationDto
    currency: string
    currencySymbol: string
    dateFormat: number
    useCommaAsDecimalPoint: boolean
    applyShiftDays: boolean
    expectedCommercialOperationalDate: string
    expectedNoticeToProceed: string
    electricityMarket: string | null
    ignoreWarnings: boolean
    customData?: TimeSeriesDto

    hasImportLimit?: boolean
    importLimit?: number
    hasImportLimitTimeSeries?: boolean
    importLimitTimeSeriesData?: TimeSeriesDto
    importLimitTimeSeriesDataName: string
    hasImportLimitSchedule?: boolean
    importScheduleLimits?: number[][]
    importScheduleLimitRates?: ScheduleRateDto[]

    hasExportLimit?: boolean
    exportLimit?: number
    hasExportLimitTimeSeries?: boolean
    exportLimitTimeSeriesData?: TimeSeriesDto
    exportLimitTimeSeriesDataName: string
    hasExportLimitSchedule?: boolean
    exportScheduleLimits?: number[][]
    exportScheduleLimitRates?: ScheduleRateDto[]

    acLineEfficiencySensitivity: SensitivityVarDto
    acTransfomerEfficiencySensitivity: SensitivityVarDto

    energyMarkets: EnergyMarketDto[]
    capacityMarkets: CapacityMarketDto[]
    contracts: ContractDto[]
    regulationMarkets: RegulationMarketDto[]
    regulationMarket?: RegulationMarketDto
    useRegulationMarket?: boolean

    bus: string
    canBatteryChargeFromGrid: string
    interconnectionLimit: number
    solarResource: ResourceDto
    solarResourceName: string
    windResource: ResourceDto
    windResourceName: string
    temperatureResource: ResourceDto
    temperatureResourceName: string
    solar: SolarDto[]
    storage: StorageDto[]
    wind: WindDto[]
    converter?: ConverterDto

    incentives: IncentiveDto[]
    systemFixedCost: number
    systemOmCost: number
    discountRate: number
    inflationRate: number
    lifetime: number
    timeStepSize: number
    earlyAnalysis: boolean
    isNewSystemConverter: boolean | null
}

export enum ComponentKind {
    homer,
    custom
}
export enum MultiYearKind {
    linear,
    custom
}
export enum SizingKind {
    absolute,
    relative
}

export interface Pos {
    latitude: number
    longitude: number
}

export interface SystemSizerLimits {
    useSolar: boolean
    useWind: boolean
    useStorage: boolean
    solarMaxSize: number
    solarMinSize: number
    windRatingSize: number
    windMaxSize: number
    windMinSize: number
    storageMaxSize: number
    storageMinSize: number
}

export interface LocationDto {
    pos: Pos
    address: string
    countryName?: string
    countryCode?: string
}

export interface EnergyMarketDto {
    kind: string
    useMultiYearPrice: boolean
    price: TimeSeriesDto
    priceName: string
    multiYearPrice: MultiYearTimeSeriesDto[]
    priceAverage: SensitivityVarDto
    priceEscalator: MultiYearVarDto
    allocation: number
    allocationSensitivity: SensitivityVarDto
    maxStorageCommitmentSensitivity: SensitivityVarDto
    pvAllocationSensitivity: SensitivityVarDto
    windAllocationSensitivity: SensitivityVarDto

    hasSeparateImportPrice: boolean
    useMultiYearImportPrice: boolean
    useImportPriceMultiplier: boolean
    importPriceMultiplier: number
    importPrice: TimeSeriesDto
    importPriceName: string
    multiYearImportPrice: MultiYearTimeSeriesDto[]
    importPriceAverage: SensitivityVarDto
    importPriceEscalator: MultiYearVarDto
}

export interface RegulationMarketDto {
    name: string
    abbreviation: string
    useRegUp: boolean
    useRegDown: boolean
    regUpPriceYear: number
    regDownPriceYear: number
    regUpPrice: TimeSeriesDto
    regUpPriceName: string
    regDownPrice: TimeSeriesDto
    regDownPriceName: string
    maxCommitmentRegUp: SensitivityVarDto
    maxCommitmentRegDown: SensitivityVarDto
    minRegUpPrice: SensitivityVarDto
    minRegDownPrice: SensitivityVarDto
    useEnergyPrice: boolean
    energyPriceYear: number
    //energyPrice: TimeSeriesDto
    energyMarketKind?: string
    throughputPercentage: SensitivityVarDto
}

export interface CapacityMarketDto {
    schedule: number[][]

    eventKind: string
    totalCallsPerYear: number
    callDuration: number
    numberOfHighestPriceDays?: number
    events: RaEventDto[]

    capacityPrice: MonthlyVarDto
    capacityPriceEscalator: MultiYearVarDto

    energyPriceKind: string
    energyMarketKind?: string
    energyPrice: number
    energyPriceEscalator: MultiYearVarDto
    allocatedStorageCapacity: number
    capacityPriceSensitivity: SensitivityVarDto,
    priceUnit: string
}

export interface RaEventDto {
    date: string
    duration: number
}

export interface ContractDto {
    energyPriceKind: string
    energyPriceRates: ScheduleRateDto[]
    energyPriceSchedule: number[][]
    energyPriceTimeSeries: TimeSeriesDto
    energyPriceEscalator: MultiYearVarDto

    obligationKind: string
    obligations: ContractObligationRequirementDto[]
    dailyObligationsTimeSeries: TimeSeriesDto
    obligationTimeSeries: TimeSeriesDto
    obligationTimeSeriesName: string
    contractedSolarCapacity?: number
    contractedWindCapacity?: number
    contractedStorageCapacity?: number
    competeWithWholesaleMarket?: boolean

    useSystemSizer?: boolean
    systemSizerLimits?: SystemSizerLimitsDto
    systemSizerResultItems?: SystemSizerResultItemDto[]
    systemSizerResultHeader?: SystemSizerResultHeaderDto

    useShortagePenalty?: boolean
    shortagePenaltyKind: string
    energyMarketKind?: string
    flatShortagePenalty: number
    shortagePenaltyPriceEscalator: MultiYearVarDto
}

export interface ScheduleRateDto {
    value: number
    color: string
}

export interface ContractObligationRequirementDto {
    dayKind: string
    startDate: string
    endDate: string
    startHour: number
    endHour: number
    value: number
    customData?: TimeSeriesDto
}

export interface SolarDto {
    kind?: string
    solarKind?: ComponentKind
    customSize?: number
    customSizeArray?: SizingVarDto
    customName?: string
    customAbbreviation?: string
    customData?: TimeSeriesDto
    size: SizingVarDto
    cost: CostTableDto
    omEscalator: MultiYearVarDto
    degradation: MultiYearVarDto
    hasConverter: boolean
    converter?: ConverterDto
    deratingFactor: SensitivityVarDto
}


export interface WindDto {
    kind: string
    specs?: WindTurbineSpec
    model: string
    size: SizingVarDto
    cost: CostTableDto
    omEscalator: MultiYearVarDto
    hubHeight: SensitivityVarDto
    lifetime: SensitivityVarDto
    availabilityLosses: number
    performanceLosses: number
    environmentalLosses: number
    wakeEffectLosses: number
    electricalLosses: number
    curtailmentLosses: number
    otherLosses: number
    altitude: number
    customSize: number //obsolete
    customSizing: SizingVarDto
    data?: TimeSeriesDto
    dataName: string
    anemometerHeight: number
    customName?: string
    customData?: string
}


export interface StorageDto {
    kind?: string
    specs?: StorageSpec
    customName?: string
    customAbbreviation?: string
    customData?: string
    model: string
    size: SizingVarDto
    hasDeferment?: boolean
    deferment?: DefermentDto
    cost: CostTableDto
    omEscalator: MultiYearVarDto
    augmentationCost?: number
    augmentationDegradationLimit: SensitivityVarDto | number
    augmentationPriceDecline: MultiYearVarDto
    cycleLimitPerDay: SensitivityVarDto | number
    useCycleLimitPerDay: boolean
    optimizeCycleLimitPerDay: boolean

    hasConverter: boolean
    converter?: ConverterDto

    useAuxiliaryLoad: boolean
    auxiliaryLoadSensitivity: SensitivityVarDto | number
    storageServesAuxiliaryLoad: boolean
    useAuxiliaryLoadFlatPrice: boolean
    auxiliaryLoadFlatPrice: number
    energyMarketKind: string | undefined
    auxiliaryLoadPriceMultiYear: MultiYearVarDto
}


export interface ConverterDto {
    size: SizingVarDto
    cost: CostTableDto
    omEscalator: MultiYearVarDto
    replacementEscalator: MultiYearVarDto
    efficiency: SensitivityVarDto
    lifetime: SensitivityVarDto
}


export interface TimeSeriesDto {
    data: number[]
    year?: number
}

export interface MultiYearTimeSeriesDto {
    data: number[]
    year?: number
    projectYearsValid: boolean[]
    timeSeriesFileBaseName: string
}

export interface SensitivityVarDto {
    values: number[]
}


export interface MultiYearItemDto {
    year: number
    value: number
}


export interface MultiYearVarDto {
    enabled: boolean
    multiYearKind: MultiYearKind
    kind?: string
    linearValue: number
    customValues: MultiYearItemDto[]
}


export interface MonthlyVarDto {
    values: number[]
}


export interface SizingVarDto {
    sizingKind: SizingKind
    kind?: string
    values: number[]
}


export interface CostTableDto {
    kind?: string//obsolete
    costTableKind?: CostTableType
    simpleCostTable?: SimpleCostTableDto
    complexCostTable?: ComplexCostTableDto
}


export interface SimpleCostTableDto {
    capital: number
    operating: number
    replacement: number
    augmentation: number
    capitalSensitivity: SensitivityVarDto
    operatingSensitivity: SensitivityVarDto
    replacementSensitivity: SensitivityVarDto
}


export interface ComplexCostItemDto {
    name: string
    units: string
    value: number
}


export interface ComplexCostTableDto {
    directCapital: ComplexCostItemDto[]
    indirectCapital: ComplexCostItemDto[]
    operating: ComplexCostItemDto[]
    replacement: ComplexCostItemDto[]
}


export interface ResourceDto {
    kind: string
    data: TimeSeriesDto
    scale: SensitivityVarDto
}


export interface IncentiveDto {
    kind: string
    solar: boolean
    storage: boolean
    wind: boolean

    eligiblePercent?: number
    itcPercent?: number
    marginalTaxPercent?: number
    bonusPercent?: number

    credit?: number
    maxCredit?: number
    useDuration?: boolean
    duration?: number
    useCreditTaxPercent?: boolean
    creditTaxPercent?: number
}

export interface SystemSizerLimitsDto {
    useSolar: boolean
    useWind: boolean
    useStorage: boolean
    solarMaxSize: number
    solarMinSize: number
    windRatingSize: number
    windMaxSize: number
    windMinSize: number
    storageMaxSize: number
    storageMinSize: number
}

export interface SystemSizerResultHeaderDto {
    optimization: SystemSizerResultsColDto[]
}
export interface SystemSizerResultsColDto {
    name: string
    units: string[]
    kind: SystemSizerResultsColKind
}
export interface SystemSizerResultItemDto {
    id: string
    results: ((number | null)[])[]
}
export interface DefermentDto {
    period: number
    periodUnit: number
    isExactDate: boolean
    startDate: string
}

export function serializeInputs(model: Project): InputsDto {
    return {
        version: 2,
        name: model.name,
        author: model.author,
        earlyAnalysis: model.earlyAnalysis,
        notes: model.notes,
        location: {
            pos: {
                latitude: model.location.pos.lat,
                longitude: model.location.pos.lng,
            },
            address: model.location.address,
            countryName: model.location.countryName,
            countryCode: model.location.countryCode,
        },
        currency: model.currency,
        currencySymbol: model.currencySymbol,
        useCommaAsDecimalPoint: model.useCommaAsDecimalPoint,
        applyShiftDays: model.applyShiftDays,
        dateFormat: model.dateFormat,

        expectedCommercialOperationalDate: serializeDate(model.expectedDate),
        expectedNoticeToProceed: serializeDate(model.noticeToProceed),
        electricityMarket: model.electricityMarket,
        ignoreWarnings: model.ignoreWarnings,

        hasImportLimit: model.hasImportLimit,
        importLimit: model.importLimit,
        hasImportLimitTimeSeries: model.hasImportLimitTimeSeries,
        importLimitTimeSeriesData: serializeTimeSeries(model.importLimitTimeSeriesData),
        importLimitTimeSeriesDataName: model.importLimitTimeSeriesDataName,
        hasImportLimitSchedule: model.hasImportLimitSchedule,
        importScheduleLimits: model.importLimitSchedule.schedule,
        importScheduleLimitRates: model.importLimitSchedule.scheduleRates.map(x => ({ value: x.value, color: x.color })),

        hasExportLimit: model.hasExportLimit,
        exportLimit: model.exportLimit,
        hasExportLimitTimeSeries: model.hasExportLimitTimeSeries,
        exportLimitTimeSeriesData: serializeTimeSeries(model.exportLimitTimeSeriesData),
        exportLimitTimeSeriesDataName: model.exportLimitTimeSeriesDataName,
        hasExportLimitSchedule: model.hasExportLimitSchedule,
        exportScheduleLimits: model.exportLimitSchedule.schedule,
        exportScheduleLimitRates: model.exportLimitSchedule.scheduleRates.map(x => ({ value: x.value, color: x.color })),

        acLineEfficiencySensitivity: serializeSensitivityVar(model.acLineEfficiencySensitivity),
        acTransfomerEfficiencySensitivity: serializeSensitivityVar(model.acTransfomerEfficiencySensitivity),

        energyMarkets: !model.useEnergyMarket ? [] :
            model.energyMarkets.map(x => serializeEnergyMarket(x)),
        capacityMarkets: !model.useCapacityMarket ? [] :
            model.capacityMarkets.map(x => serializeCapacityMarket(x)),
        contracts: !model.useContract ? [] :
            model.contracts.map(x => serializeContract(x)),
        regulationMarkets: !model.useRegulationMarket ? [] :
            model.regulationMarkets.map(x => serializeRegulationMarket(x)),
        useRegulationMarket: undefined,

        bus: model.bus,
        canBatteryChargeFromGrid: ((model.useStorage && !model.useSolar) || (model.useStorage && model.useWind && !model.useSolar)) ? "true" : model.canBatteryChargeFromGrid,
        interconnectionLimit: model.interconnectionLimit,
        solarResource: serializeResource(model.solarResource),
        solarResourceName: model.solarResourceName,
        windResource: serializeResource(model.windResource),
        windResourceName: model.windResourceName,
        temperatureResource: serializeResource(model.temperatureResource),
        temperatureResourceName: model.temperatureResourceName,
        solar: !model.useSolar ? [] :
            model.solar.map(x => serializeSolar(x)),
        storage: !model.useStorage ? [] :
            model.storage.map(x => serializeStorage(x)),
        wind: !model.useWind ? [] :
            model.wind.map(x => serializeWind(x)),
        converter: model.hasSystemConverter ? serializeConverter(model.inverter) : undefined,

        incentives: model.incentives.map(x => serializeIncentive(x)),
        systemFixedCost: model.systemFixedCost,
        systemOmCost: model.systemOmCost,
        discountRate: model.discountRate,
        inflationRate: model.inflationRate,
        lifetime: model.lifetime,
        timeStepSize: model.timeStepSize,
        isNewSystemConverter: model.hasSystemConverter ? false : model.isNewSystemConverter
    }
}


function serializeEnergyMarket(model: EnergyMarket): EnergyMarketDto {
    return {
        kind: model.kind,
        useMultiYearPrice: model.useMultiYearPrice,
        price: serializeTimeSeries(model.price),
        priceName: model.priceName,
        multiYearPrice: model.multiYearPrice.map(x => serializeMultiYearTimeSeries(x)),
        priceAverage: serializeSensitivityVar(model.priceAverage),
        priceEscalator: serializeMultiYearVar(model.priceEscalator),
        allocation: model.allocation,
        allocationSensitivity: serializeSensitivityVar(model.allocationSensitivity),
        maxStorageCommitmentSensitivity: serializeSensitivityVar(model.maxStorageCommitmentSensitivity),
        windAllocationSensitivity: serializeSensitivityVar(model.windAllocationSensitivity),
        pvAllocationSensitivity: serializeSensitivityVar(model.pvAllocationSensitivity),

        hasSeparateImportPrice: model.hasSeparateImportPrice,
        useMultiYearImportPrice: model.useMultiYearImportPrice,
        useImportPriceMultiplier: model.useImportPriceMultiplier,
        importPriceMultiplier: model.importPriceMultiplier,
        importPrice: serializeTimeSeries(model.importPrice),
        importPriceName: model.importPriceName,
        multiYearImportPrice: model.multiYearImportPrice.map(x => serializeMultiYearTimeSeries(x)),
        importPriceAverage: serializeSensitivityVar(model.importPriceAverage),
        importPriceEscalator: serializeMultiYearVar(model.importPriceEscalator),
    }
}

function serializeRegulationMarket(model: RegulationMarket): RegulationMarketDto {
    return {
        name: model.name,
        abbreviation: model.abbreviation,
        useRegUp: model.useRegUp,
        useRegDown: model.useRegDown,
        regUpPriceYear: model.regUpPriceYear,
        regDownPriceYear: model.regDownPriceYear,
        regUpPrice: serializeTimeSeries(model.regUpPrice),
        regUpPriceName: model.regUpPriceName,
        regDownPrice: serializeTimeSeries(model.regDownPrice),
        regDownPriceName: model.regDownPriceName,
        maxCommitmentRegUp: serializeSensitivityVar(model.maxCommitmentRegUp),
        maxCommitmentRegDown: serializeSensitivityVar(model.maxCommitmentRegDown),
        minRegUpPrice: serializeSensitivityVar(model.minRegUpPrice),
        minRegDownPrice: serializeSensitivityVar(model.minRegDownPrice),
        useEnergyPrice: model.useEnergyPrice,
        energyPriceYear: model.energyPriceYear,
        energyMarketKind: model.energyMarket?.kind ?? 'dam',
        //energyPrice: serializeTimeSeries(model.energyPrice),
        throughputPercentage: serializeSensitivityVar(model.throughputPercentage),
    }
}


function serializeCapacityMarket(model: CapacityMarket): CapacityMarketDto {
    return {
        schedule: model.schedule,

        eventKind: model.eventKind,
        totalCallsPerYear: model.totalCallsPerYear,
        callDuration: model.averageCallDuration,
        numberOfHighestPriceDays: model.numberOfHighestPriceDays,
        events: model.events.map(x => ({
            date: serializeDate(x.date),
            duration: x.duration,
        })),

        capacityPrice: serializeMonthlyVar(model.price),
        capacityPriceSensitivity: serializeSensitivityVar(model.capacityPriceSensitivity),
        priceUnit: model.priceUnit,
        capacityPriceEscalator: serializeMultiYearVar(model.capacityEscalator),

        energyPriceKind: model.energyPriceKind,
        energyMarketKind: model.energyMarket?.kind ?? undefined,
        energyPrice: model.energyPrice,
        energyPriceEscalator: serializeMultiYearVar(model.energyEscalator),
        allocatedStorageCapacity: model.allocatedStorageCapacity
    }
}


function serializeContract(model: Contract): ContractDto {
    return {
        energyPriceKind: model.energyPriceKind,
        energyPriceRates: model.energyPriceSchedule.scheduleRates.map(x => ({
            value: x.value,
            color: x.color,
        })),
        energyPriceSchedule: model.energyPriceSchedule.schedule,
        energyPriceTimeSeries: serializeTimeSeries(model.energyPriceTimeSeries),
        energyPriceEscalator: serializeMultiYearVar(model.energyPriceEscalator),

        obligationKind: model.obligationKind,
        obligations: model.obligations.map(x => ({
            dayKind: x.dayKind,
            startDate: serializeDate(x.startDate),
            endDate: serializeDate(x.endDate),
            startHour: x.startHour,
            endHour: x.endHour,
            value: x.value,
            customData: serializeTimeSeries(x.obligationTimeSeries)
        })),
        dailyObligationsTimeSeries: serializeTimeSeries(model.dailyObligationsTimeSeries),
        obligationTimeSeries: serializeTimeSeries(model.obligationTimeSeries),
        obligationTimeSeriesName: model.obligationTimeSeriesName,
        contractedSolarCapacity: model.contractedSolarCapacity,
        contractedWindCapacity: model.contractedWindCapacity,
        contractedStorageCapacity: model.obligationKind === 'none' ? model.contractedStorageCapacity : 0,
        competeWithWholesaleMarket: model.competeWithWholesaleMarket,

        useSystemSizer: model.useSystemSizer,
        systemSizerLimits: model.systemSizerLimits,
        systemSizerResultItems: model.resultTable.items.map(x => ({ id: x.id, results: x.results })),
        systemSizerResultHeader: model.resultTable.header,

        useShortagePenalty: model.useShortagePenalty,
        shortagePenaltyKind: model.shortagePenaltyKind,
        energyMarketKind: model.energyMarket?.kind ?? undefined,
        flatShortagePenalty: model.flatShortagePenalty,
        shortagePenaltyPriceEscalator: serializeMultiYearVar(model.shortagePenaltyPriceEscalator)
    }
}

function serializeSolar(model: Solar): SolarDto {
    return {
        solarKind: model.kind,
        customSize: model.customSize,
        customSizeArray: serializeSizingVar(model.customSizeArray),
        customData: serializeTimeSeries(model.customData),
        customName: model.customName,
        customAbbreviation: model.customAbbreviation,
        size: serializeSizingVar(model.sizing),
        cost: serializeCostTable(model.cost),
        omEscalator: serializeMultiYearVar(model.omEscalator),
        degradation: serializeMultiYearVar(model.degradation),
        hasConverter: model.hasConverter,
        converter: serializeConverter(model.converter),
        deratingFactor: serializeSensitivityVar(model.deratingFactor),
    }
}


function serializeWind(model: Wind): WindDto {
    return {
        kind: model.kind,
        specs: model.specs,
        model: model.model,
        customSize: 0, //obsolete, kept for retrocompatibility
        customSizing: serializeSizingVar(model.customSizing),
        data: serializeTimeSeries(model.data),
        dataName: model.dataName,
        size: serializeSizingVar(model.sizing),
        cost: serializeCostTable(model.cost),
        hubHeight: serializeSensitivityVar(model.hubHeight),
        lifetime: serializeSensitivityVar(model.lifetime),
        availabilityLosses: model.availabilityLosses,
        performanceLosses: model.performanceLosses,
        environmentalLosses: model.environmentalLosses,
        wakeEffectLosses: model.wakeEffectLosses,
        electricalLosses: model.electricalLosses,
        curtailmentLosses: model.curtailmentLosses,
        otherLosses: model.otherLosses,
        altitude: model.altitude,
        anemometerHeight: model.anemometerHeight,
        customName: model.customName,
        customData: model.customData,
        omEscalator: serializeMultiYearVar(model.omEscalator),
    }
}


function serializeStorage(model: Storage): StorageDto {
    return {
        kind: model.kind,
        specs: model.specs,
        model: model.model,
        customName: model.customName,
        customAbbreviation: model.customAbbreviation,
        customData: model.customData,
        size: serializeSizingVar(model.sizing),
        cost: serializeCostTable(model.cost),
        omEscalator: serializeMultiYearVar(model.omEscalator),
        augmentationCost: model.augmentationCost,
        augmentationDegradationLimit: serializeSensitivityVar(model.augmentationDegradationLimit),
        augmentationPriceDecline: serializeMultiYearVar(model.augmentationPriceDecline),
        hasConverter: model.hasConverter,
        converter: serializeConverter(model.converter),
        useCycleLimitPerDay: model.useCycleLimitPerDay,
        optimizeCycleLimitPerDay: model.optimizeCycleLimitPerDay,
        cycleLimitPerDay: serializeSensitivityVar(model.cycleLimitPerDay),
        useAuxiliaryLoad: model.useAuxiliaryLoad,
        auxiliaryLoadSensitivity: serializeSensitivityVar(model.auxiliaryLoadSensitivity),
        storageServesAuxiliaryLoad: model.storageServesAuxiliaryLoad,
        useAuxiliaryLoadFlatPrice: model.useAuxiliaryLoadFlatPrice,
        auxiliaryLoadFlatPrice: model.auxiliaryLoadFlatPrice,
        energyMarketKind: model.energyMarket?.kind ?? undefined,
        auxiliaryLoadPriceMultiYear: serializeMultiYearVar(model.auxiliaryLoadPriceMultiYear),
        hasDeferment: model.hasDeferment,
        deferment: serealizeDeferment(model.deferment)
    }
}


function serializeConverter(model: Inverter): ConverterDto {
    return {
        size: serializeSizingVar(model.sizing),
        cost: serializeCostTable(model.cost),
        omEscalator: serializeMultiYearVar(model.omEscalator),
        replacementEscalator: serializeMultiYearVar(model.replacementEscalator),
        efficiency: serializeSensitivityVar(model.efficiency),
        lifetime: serializeSensitivityVar(model.lifetime),
    }
}


function serializeResource(model: Resource): ResourceDto {
    return {
        kind: model.kind,
        scale: serializeSensitivityVar(model.scale),
        data: serializeTimeSeries(model.data),
    }
}


function serializeMultiYearVar(model: MultiYearVar): MultiYearVarDto {
    return {
        enabled: model.enabled,
        multiYearKind: model.multiYearKind,
        linearValue: model.linearValue,
        customValues: model.customValues.map(x => ({ year: x.year, value: x.value })),
    }
}

function serealizeDeferment(model: Deferment): DefermentDto {
    return {
        isExactDate: model.isExactDate,
        period: Math.floor(model.period),
        periodUnit: model.periodUnit,
        startDate: serializeDate(model.startDate)
    }
}

export function serializeSensitivityVar(model: SensitivityVar): SensitivityVarDto {
    return {
        values: model.values.map(x => x.value),
    }
}


function serializeSizingVar(model: Sizing): SizingVarDto {
    const values = model.values.map(x => x.value)
    return {
        sizingKind: model.kind,
        values: values,
    }
}


function serializeMonthlyVar(model: MonthlyVar): MonthlyVarDto {
    return {
        values: model.values.map(x => x.value),
    }
}


function serializeCostTable(model: CostTable): CostTableDto {
    return {
        costTableKind: model.costTableType,
        simpleCostTable: model.costTableType === CostTableType.simple ? serializeSimpleCostTable(model.simple) : undefined,
        complexCostTable: model.costTableType === CostTableType.complex ? serializeComplexCostTable(model.complex) : undefined,
    }
}


function getRelative(x: number, base: number) {
    return eq(base, 0) ? 1 : x / base
}

function serializeSimpleCostTable(model: SimpleCostTable): SimpleCostTableDto {
    const base = model.items[0]
    return {
        capital: base.capital,
        operating: base.operating,
        replacement: base.replacement,
        augmentation: base.augmentation,
        capitalSensitivity: { values: model.items.map(x => getRelative(x.capital, base.capital)) },
        operatingSensitivity: { values: model.items.map(x => getRelative(x.operating, base.operating)) },
        replacementSensitivity: { values: model.items.map(x => getRelative(x.replacement, base.replacement)) },
    }
}


function serializeComplexCostTable(model: ComplexCostTable): ComplexCostTableDto {
    return {
        directCapital: model.directCapital.items.map(x => serializeComplexCostItem(x)),
        indirectCapital: model.indirectCapital.items.map(x => serializeComplexCostItem(x)),
        operating: model.operating.items.map(x => serializeComplexCostItem(x)),
        replacement: model.replacement.items.map(x => serializeComplexCostItem(x)),
    }
}


function serializeComplexCostItem(model: ComplexCostItem): ComplexCostItemDto {
    return {
        name: model.name,
        units: model.unit,
        value: model.value,
    }
}


function serializeTimeSeries(model: TimeSeries): TimeSeriesDto {
    return {
        data: model.data,
        year: model.year,
    }
}

function serializeMultiYearTimeSeries(model: MultiYearTimeSeries): MultiYearTimeSeriesDto {
    return {
        data: model.price.data,
        year: model.price.year,
        projectYearsValid: model.projectYearsValid,
        timeSeriesFileBaseName: model.timeSeriesFileBaseName
    }
}

function serializeIncentive(model: Incentive): IncentiveDto {
    let rv: IncentiveDto = {
        kind: model.kind,
        solar: model.solar,
        storage: model.storage,
        wind: model.wind,
    }
    switch (model.kind) {
        case 'itc': {
            const x = model as InvestmentTaxCredit
            rv.eligiblePercent = x.eligiblePercent
            rv.itcPercent = x.itcPercent
            break
        }
        case 'macrs': {
            const x = model as Macrs
            rv.eligiblePercent = x.eligiblePercent
            rv.marginalTaxPercent = x.marginalTaxPercent
            break
        }
        case 'bonus': {
            const x = model as BonusDepreciation
            rv.eligiblePercent = x.eligiblePercent
            rv.marginalTaxPercent = x.marginalTaxPercent
            rv.bonusPercent = x.bonusPercent
            break
        }
        case 'prod': {
            const x = model as ProductionBased
            rv.credit = x.credit
            rv.maxCredit = x.maxCredit
            rv.useDuration = x.useDuration
            rv.duration = x.duration
            rv.useCreditTaxPercent = x.useCreditTaxPercent
            rv.creditTaxPercent = x.creditTaxPercent
            break
        }
    }
    return rv
}


function serializeDate(x: Date): string {
    // Adjust time to have the same time as entered by user but in UTC time zone for a server
    // Example: user 22:35 at UTC+3 -> server 22:35 at UTC+0
    const offsetMs = x.getTimezoneOffset() * 60 * 1000
    const server = new Date(x.getTime() - offsetMs)
    return server.toISOString()
}


export function deserializeInputs(dto: InputsDto, model: Project) {
    maintainCompatibility(dto)

    model.location.pos = { lat: dto.location.pos.latitude, lng: dto.location.pos.longitude }
    model.location.address = dto.location.address
    model.location.countryName = dto.location.countryName ?? 'United States'
    model.location.countryCode = dto.location.countryCode ?? 'US'
    model.useCommaAsDecimalPoint = (model.useCommaAsDecimalPoint != null && model.useCommaAsDecimalPoint !== undefined) ? model.useCommaAsDecimalPoint : false
    model.applyShiftDays = dto.applyShiftDays ?? true
    model.dateFormat = (model.dateFormat != null && model.dateFormat !== undefined) ? model.dateFormat : 0
    model.name = dto.name
    model.author = dto.author
    model.earlyAnalysis = dto.earlyAnalysis
    model.notes = dto.notes
    model.expectedDate = deserializeDate(dto.expectedCommercialOperationalDate)
    model.noticeToProceed = deserializeDate(dto.expectedNoticeToProceed)
    model.electricityMarket = dto.electricityMarket ?? 'other'
    model.ignoreWarnings = dto.ignoreWarnings ?? false

    model.useEnergyMarket = dto.energyMarkets?.length > 0
    model.useCapacityMarket = dto.capacityMarkets?.length > 0
    model.useContract = dto.contracts?.length > 0
    model.energyMarkets.splice(0, model.energyMarkets.length,
        ...dto.energyMarkets?.map(x => deserializeEnergyMarket(x, model.lifetime, dto.version!)) ?? [])
    model.capacityMarkets.splice(0, model.capacityMarkets.length,
        ...dto.capacityMarkets?.map(x => deserializeCapacityMarket(x, model.energyMarkets, model.lifetime, dto.version!)) ?? [])
    model.contracts.splice(0, model.contracts.length,
        ...dto.contracts?.map(x => deserializeContract(x, model.lifetime, model.id, model.energyMarkets, dto.version!)) ?? [])
    model.regulationMarkets.splice(0, model.regulationMarkets.length,
        ...dto.regulationMarkets?.map(x => deserializeRegulationMarket(x, model.energyMarkets)) ?? [])
    if (model.regulationMarkets.length === 0 && dto.useRegulationMarket === true) {
        model.regulationMarkets.push(deserializeRegulationMarket(dto.regulationMarket, model.energyMarkets))
    }
    model.useRegulationMarket = model.regulationMarkets?.length > 0

    model.useSolar = dto.solar.length > 0
    model.useStorage = dto.storage.length > 0
    model.useWind = dto.wind?.length > 0
    model.solar.splice(0, model.solar.length,
        ...dto.solar.map(x => deserializeSolar(x)))
    model.storage.splice(0, model.storage.length,
        ...dto.storage.map(x => deserializeStorage(x, model.energyMarkets, model.expectedDate, dto.version!)))
    model.wind.splice(0, model.wind.length,
        ...dto.wind?.map(x => deserializeWind(x)))
    model.inverter = deserializeConverter(dto.converter)
    model.bus = dto.bus as BusKind
    model.canBatteryChargeFromGrid = dto.canBatteryChargeFromGrid.toString()
    model.interconnectionLimit = -1;

    model.hasImportLimit = dto.hasImportLimit ?? false
    model.importLimit = dto.interconnectionLimit >= 0 ? dto.interconnectionLimit : dto.importLimit != undefined ? dto.importLimit : 100000
    model.hasImportLimitTimeSeries = dto.hasImportLimitTimeSeries ?? false
    model.importLimitTimeSeriesData = deserializeTimeSeries(dto.importLimitTimeSeriesData)
    model.importLimitTimeSeriesDataName = dto.importLimitTimeSeriesDataName !== undefined && dto.importLimitTimeSeriesDataName !== '' ? dto.importLimitTimeSeriesDataName : 'Import Limit Data'
    model.hasImportLimitSchedule = dto.hasImportLimitSchedule ?? false
    model.importLimitSchedule = deserializeRateSchedule(dto.importScheduleLimitRates, dto.importScheduleLimits)

    model.hasExportLimit = dto.hasExportLimit ?? true
    model.exportLimit = dto.interconnectionLimit >= 0 ? dto.interconnectionLimit : dto.exportLimit != undefined ? dto.exportLimit : 100000
    model.hasExportLimitTimeSeries = dto.hasExportLimitTimeSeries ?? false
    model.exportLimitTimeSeriesData = deserializeTimeSeries(dto.exportLimitTimeSeriesData)
    model.exportLimitTimeSeriesDataName = dto.exportLimitTimeSeriesDataName !== undefined && dto.exportLimitTimeSeriesDataName !== '' ? dto.exportLimitTimeSeriesDataName : 'Export Limit Data'
    model.hasExportLimitSchedule = dto.hasExportLimitSchedule ?? false
    model.exportLimitSchedule = deserializeRateSchedule(dto.exportScheduleLimitRates, dto.exportScheduleLimits)

    model.acLineEfficiencySensitivity = dto.acLineEfficiencySensitivity != undefined ? deserializeSensitivityVar(dto.acLineEfficiencySensitivity) : deserializeSensitivityVar(100)
    model.acTransfomerEfficiencySensitivity = dto.acTransfomerEfficiencySensitivity != undefined ? deserializeSensitivityVar(dto.acTransfomerEfficiencySensitivity) : deserializeSensitivityVar(100)

    model.solarResource = deserializeResource(dto.solarResource)
    model.solarResourceName = dto.solarResourceName !== undefined && dto.solarResourceName !== '' ? dto.solarResourceName : 'Solar Resource Imported Data'
    model.windResource = deserializeResource(dto.windResource)
    model.windResourceName = dto.windResourceName !== undefined && dto.windResourceName !== '' ? dto.windResourceName : 'Wind Resource Imported Data'
    model.temperatureResource = deserializeResource(dto.temperatureResource)
    model.temperatureResourceName = dto.temperatureResourceName !== undefined && dto.temperatureResourceName !== '' ? dto.temperatureResourceName : 'Temperature Resource Imported Data'

    model.systemFixedCost = dto.systemFixedCost
    model.systemOmCost = dto.systemOmCost
    model.discountRate = dto.discountRate
    model.inflationRate = dto.inflationRate
    model.lifetime = dto.lifetime
    model.timeStepSize = dto.timeStepSize
    model.isNewSystemConverter = model.isNewSystemConverter
    model.incentives.splice(0, model.incentives.length,
        ...dto.incentives.map(x => deserializeIncentive(x)))
    if (dto.version! < 2 && (dto.fileVersion === undefined || dto.fileVersion !== '1.0')) {
        if (model.regulationMarkets?.length > 0 && !model.regulationMarkets[0].useEnergyPrice)
            model.regulationMarkets[0].throughputPercentage = deserializeSensitivityVar(100)
    }
}


function deserializeEnergyMarket(dto: EnergyMarketDto, lifetime: number, fileVersion: number): EnergyMarket {
    let rv = new EnergyMarket(lifetime)
    rv.kind = dto.kind as EnergyMarketKind
    rv.useMultiYearPrice = dto.useMultiYearPrice ?? false
    rv.price = deserializeTimeSeries(dto.price)
    rv.priceName = dto.priceName !== undefined && dto.priceName !== '' ? dto.priceName : 'Energy Price'
    rv.multiYearPrice = dto.multiYearPrice ? dto.multiYearPrice.map(x => deserializeMultiYearTimeSeries(x)) : new Array<MultiYearTimeSeries>()
    rv.priceAverage = deserializeSensitivityVar(dto.priceAverage)
    rv.priceEscalator = deserializeMultiYearVar(dto.priceEscalator)
    rv.allocation = dto.allocation
    //Maintain compatibility with old projects with single Allocation
    if (dto.allocationSensitivity)
        rv.allocationSensitivity = deserializeSensitivityVar(dto.allocationSensitivity)
    else if (dto.allocation)
        rv.allocationSensitivity = deserializeSensitivityVar(dto.allocation)
    else
        rv.allocationSensitivity = deserializeSensitivityVar(100)
    if (dto.maxStorageCommitmentSensitivity !== undefined && dto.maxStorageCommitmentSensitivity.values.length > 0)
        rv.maxStorageCommitmentSensitivity = deserializeSensitivityVar(dto.maxStorageCommitmentSensitivity)
    else
        rv.maxStorageCommitmentSensitivity = rv.allocationSensitivity
    if (dto.pvAllocationSensitivity !== undefined && dto.pvAllocationSensitivity.values.length > 0)
        rv.pvAllocationSensitivity = deserializeSensitivityVar(dto.pvAllocationSensitivity)
    else
        rv.pvAllocationSensitivity = rv.allocationSensitivity
    if (dto.windAllocationSensitivity !== undefined && dto.windAllocationSensitivity.values.length > 0)
        rv.windAllocationSensitivity = deserializeSensitivityVar(dto.windAllocationSensitivity)
    else
        rv.windAllocationSensitivity = rv.allocationSensitivity

    rv.hasSeparateImportPrice = dto.hasSeparateImportPrice ?? false
    rv.useImportPriceMultiplier = dto.useImportPriceMultiplier ?? true
    //rv.useMultiYearImportPrice = dto.useMultiYearImportPrice ?? false
    rv.importPriceMultiplier = dto.importPriceMultiplier ?? 1;
    //rv.importPrice = deserializeTimeSeries(dto.importPrice)
    //rv.importPriceName = dto.importPriceName !== undefined && dto.importPriceName !== '' ? dto.importPriceName : 'Import Energy Price'
    //rv.multiYearImportPrice = dto.multiYearImportPrice ? dto.multiYearImportPrice.map(x => deserializeMultiYearTimeSeries(x)) : new Array<MultiYearTimeSeries>()
    //rv.importPriceAverage = deserializeSensitivityVar(dto.importPriceAverage)
    //rv.importPriceEscalator = deserializeMultiYearVar(dto.importPriceEscalator)
    return rv
}

function deserializeRegulationMarket(dto?: RegulationMarketDto, energyMarkets?: EnergyMarket[]): RegulationMarket {
    let rv = new RegulationMarket()
    if (!dto || !energyMarkets) return rv

    rv.name = dto.name
    rv.abbreviation = dto.abbreviation
    rv.useRegUp = dto.useRegUp
    rv.regUpPriceYear = dto.regUpPriceYear
    rv.regUpPrice = deserializeTimeSeries(dto.regUpPrice)
    rv.regUpPriceName = dto.regUpPriceName !== undefined && dto.regUpPriceName !== '' ? dto.regUpPriceName : 'Reg Up Price'
    if (dto.maxCommitmentRegUp)
        rv.maxCommitmentRegUp = deserializeSensitivityVar(dto.maxCommitmentRegUp)
    if (dto.minRegUpPrice)
        rv.minRegUpPrice = deserializeSensitivityVar(dto.minRegUpPrice)

    rv.useRegDown = dto.useRegDown
    rv.regDownPriceYear = dto.regDownPriceYear
    rv.regDownPrice = deserializeTimeSeries(dto.regDownPrice)
    rv.regDownPriceName = dto.regDownPriceName !== undefined && dto.regDownPriceName !== '' ? dto.regDownPriceName : 'Reg Down Price'
    if (dto.maxCommitmentRegDown)
        rv.maxCommitmentRegDown = deserializeSensitivityVar(dto.maxCommitmentRegDown)
    if (dto.minRegDownPrice)
        rv.minRegDownPrice = deserializeSensitivityVar(dto.minRegDownPrice)

    rv.useEnergyPrice = dto.useEnergyPrice
    rv.energyPriceYear = dto.energyPriceYear
    const x = energyMarkets.find(m => m.kind === dto.energyMarketKind)
    if (!x) {
        rv.energyMarket = energyMarkets[0]
    } else {
        rv.energyMarket = x
    }

    rv.throughputPercentage = dto.throughputPercentage?.values.length > 0 ? deserializeSensitivityVar(dto.throughputPercentage) : deserializeSensitivityVar(100)
    return rv
}

function deserializeCapacityMarket(dto: CapacityMarketDto, energyMarkets: EnergyMarket[], lifetime: number, fileVersion: number): CapacityMarket {
    let rv = new CapacityMarket(lifetime)
    rv.schedule = dto.schedule
    rv.price = deserializeMonthlyVar(dto.capacityPrice)
    rv.capacityPriceSensitivity = dto.capacityPriceSensitivity == undefined ? deserializeSensitivityVar(1) : deserializeSensitivityVar(dto.capacityPriceSensitivity)
    rv.priceUnit = dto.priceUnit ? dto.priceUnit as PriceUnit : '$/kW-mo' as PriceUnit
    rv.capacityEscalator = deserializeMultiYearVar(dto.capacityPriceEscalator)
    rv.energyEscalator = deserializeMultiYearVar(dto.energyPriceEscalator)
    rv.eventKind = dto.eventKind as RaEventKind

    rv.energyPriceKind = dto.energyPriceKind as EnergyPriceKind
    if (rv.energyPriceKind === 'market') {
        const x = energyMarkets.find(m => m.kind === dto.energyMarketKind)
        if (!x) {
            rv.energyPriceKind = 'flat'
            rv.energyMarket = null
        } else {
            rv.energyMarket = x
        }
    }
    rv.energyPrice = dto.energyPrice

    rv.totalCallsPerYear = dto.totalCallsPerYear
    rv.averageCallDuration = dto.callDuration
    rv.numberOfHighestPriceDays = dto.numberOfHighestPriceDays ?? 0
    rv.allocatedStorageCapacity = dto.allocatedStorageCapacity ?? 0

    rv.events.splice(0, rv.events.length,
        ...dto.events.map(x => new RaEvent({ date: deserializeDate(x.date), duration: x.duration })))
    return rv
}


function deserializeContract(dto: ContractDto, lifetime: number, projId: string, energyMarkets: EnergyMarket[], fileVersion: number): Contract {
    let rv = new Contract(lifetime, projId)
    rv.energyPriceKind = dto.energyPriceKind as ContractEnergyPriceKind
    rv.energyPriceSchedule.scheduleRates.splice(0, rv.energyPriceSchedule.scheduleRates.length,
        ...dto.energyPriceRates.map(x => new ScheduleRate({
            color: x.color,
            value: x.value,
        })))
    rv.energyPriceSchedule.schedule = dto.energyPriceSchedule
    rv.energyPriceTimeSeries = deserializeTimeSeries(dto.energyPriceTimeSeries)
    rv.energyPriceEscalator = deserializeMultiYearVar(dto.energyPriceEscalator)

    rv.obligationKind = dto.obligationKind as ContractObligationKind
    rv.obligations.splice(0, rv.obligations.length,
        ...dto.obligations.map(x => new ContractObligationRequirement({
            dayKind: x.dayKind as DayKind,
            startDate: deserializeDate(x.startDate),
            endDate: deserializeDate(x.endDate), 
            startHour: x.startHour,
            endHour: x.endHour,
            value: x.value,
            obligationTimeSeries: deserializeTimeSeries(x.customData)
        })))
    rv.dailyObligationsTimeSeries = deserializeTimeSeries(dto.dailyObligationsTimeSeries)

    rv.obligationTimeSeries = deserializeTimeSeries(dto.obligationTimeSeries)
    rv.obligationTimeSeriesName = dto.obligationTimeSeriesName !== undefined && dto.obligationTimeSeriesName !== '' ? dto.obligationTimeSeriesName : 'Delivery Profile'
    rv.contractedSolarCapacity = dto.contractedSolarCapacity ?? 0
    rv.contractedWindCapacity = dto.contractedWindCapacity ?? 0
    rv.contractedStorageCapacity = dto.contractedStorageCapacity ?? 0
    rv.competeWithWholesaleMarket = dto.competeWithWholesaleMarket ?? false

    rv.useSystemSizer = dto.useSystemSizer ?? false
    rv.systemSizerLimits = deserializeSystemSizerLimits(dto.systemSizerLimits)
    if (dto.systemSizerResultItems && dto.systemSizerResultItems!.length > 0) {
        rv.resultTable.items.splice(0, dto.systemSizerResultItems!.length,
                ...dto.systemSizerResultItems!.map(x =>({
                    id: x.id,
                    results: x.results,
                })))
        rv.resultTable.header = dto.systemSizerResultHeader!
    }

    rv.useShortagePenalty = dto.useShortagePenalty ?? false
    rv.shortagePenaltyKind = dto.shortagePenaltyKind as ShortagePenaltyKind
    if (rv.shortagePenaltyKind === 'market' || rv.shortagePenaltyKind === 'delta') {
        const x = energyMarkets.find(m => m.kind === dto.energyMarketKind)
        if (!x) {
            rv.shortagePenaltyKind = 'flat'
            rv.energyMarket = null
        } else {
            rv.energyMarket = x
        }
    }
    else rv.energyMarket = null
    rv.flatShortagePenalty = dto.flatShortagePenalty ?? 0
    rv.shortagePenaltyPriceEscalator = deserializeMultiYearVar(dto.shortagePenaltyPriceEscalator)

    return rv
}

function deserializeSystemSizerLimits(value?: SystemSizerLimitsDto): SystemSizerLimits {
    let rv = new SystemSizerLimitsClass()
    rv.useSolar = value?.useSolar ?? true
    rv.useWind = value?.useWind ?? true
    rv.useStorage = value?.useStorage ?? true
    rv.solarMaxSize = value?.solarMaxSize ?? 0
    rv.solarMinSize = value?.solarMinSize ?? 0
    //windRatingSize  0
    rv.windMaxSize = value?.windMaxSize ?? 0
    rv.windMinSize = value?.windMinSize ?? 0
    rv.storageMaxSize = value?.storageMaxSize ?? 0
    rv.storageMinSize = value?.storageMinSize ?? 0
    return rv
}

function deserializeSolar(x: SolarDto): Solar {
    let rv = new Solar()
    rv.kind = x.solarKind ?? ComponentKind.homer
    rv.customSize = x.customSize !== undefined && x.customSize > 0 ? x.customSize : 0
    rv.customSizeArray = (x.customSizeArray === undefined || x.customSizeArray.values.length === 0) && x.customSize !== undefined && x.customSize > 0 ? deserializeSizingVar(x.customSize) : x.customSizeArray === undefined || x.customSizeArray.values.length === 0 ? deserializeSizingVar(0) : deserializeSizingVar(x.customSizeArray!)
    rv.customName = x.customName ?? ''
    rv.customAbbreviation = x.customAbbreviation ?? ''
    rv.customData = deserializeTimeSeries(x.customData)
    rv.sizing = deserializeSizingVar(x.size)
    rv.cost = deserializeCostTable(x.cost)
    rv.omEscalator = deserializeMultiYearVar(x.omEscalator)
    rv.degradation = deserializeMultiYearVar(x.degradation)
    rv.hasConverter = x.hasConverter
    rv.converter = deserializeConverter(x.converter)
    rv.deratingFactor = x.deratingFactor?.values.length > 0 ? deserializeSensitivityVar(x.deratingFactor) : deserializeSensitivityVar(100)
    return rv
}



function deserializeWind(x: WindDto): Wind {
    let rv = new Wind()
    rv.kind = x.kind as WindTurbineKind ?? 'library' as WindTurbineKind
    rv.specs = x.specs
    rv.model = x.model
    rv.customSizing = !Number.isNaN(x.customSize) && Number(x.customSize) > 0 ? deserializeSizingVar({ sizingKind: SizingKind.absolute, values: [Number(x.customSize)] }) : deserializeSizingVar(x.customSizing)
    rv.data = deserializeTimeSeries(x.data)
    rv.dataName = x.dataName !== undefined && x.dataName !== '' ? x.dataName : 'Imported Wind Production'
    rv.sizing = deserializeSizingVar(x.size)
    rv.cost = deserializeCostTable(x.cost)
    rv.hubHeight = deserializeSensitivityVar(x.hubHeight)
    rv.lifetime = deserializeSensitivityVar(x.lifetime)
    rv.availabilityLosses = x.availabilityLosses
    rv.performanceLosses = x.performanceLosses
    rv.environmentalLosses = x.environmentalLosses
    rv.wakeEffectLosses = x.wakeEffectLosses
    rv.electricalLosses = x.electricalLosses
    rv.curtailmentLosses = x.curtailmentLosses
    rv.otherLosses = x.otherLosses
    rv.altitude = x.altitude
    rv.anemometerHeight = x.anemometerHeight
    rv.customName = x.customName ?? ''
    rv.customData = x.customData ?? ''
    rv.omEscalator = deserializeMultiYearVar(x.omEscalator)
    return rv
}


function deserializeStorage(x: StorageDto, energyMarkets: EnergyMarket[], expectedDate: Date, fileVersion: number): Storage {
    let rv = new Storage()
    rv.kind = x.kind as StorageKind ?? 'library' as StorageKind
    rv.specs = x.specs
    rv.model = x.model
    rv.customName = x.customName ?? ''
    rv.customAbbreviation = x.customAbbreviation ?? ''
    rv.customData = x.customData ?? ''
    rv.sizing = deserializeSizingVar(x.size)
    rv.cost = deserializeCostTable(x.cost)
    rv.omEscalator = deserializeMultiYearVar(x.omEscalator)
    rv.augmentationCost = x.augmentationCost ?? 0
    rv.augmentationDegradationLimit = deserializeSensitivityVar(x.augmentationDegradationLimit)
    rv.augmentationPriceDecline = deserializeMultiYearVar(x.augmentationPriceDecline)
    rv.optimizeCycleLimitPerDay = x.optimizeCycleLimitPerDay ?? false
    rv.useCycleLimitPerDay = x.useCycleLimitPerDay ?? false
    rv.cycleLimitPerDay = x.cycleLimitPerDay ? deserializeSensitivityVar(x.cycleLimitPerDay) : deserializeSensitivityVar(1)

    rv.useAuxiliaryLoad = x.useAuxiliaryLoad ?? false
    rv.auxiliaryLoadSensitivity = x.auxiliaryLoadSensitivity ? deserializeSensitivityVar(x.auxiliaryLoadSensitivity) : deserializeSensitivityVar(1)
    rv.storageServesAuxiliaryLoad = x.storageServesAuxiliaryLoad ?? false
    rv.useAuxiliaryLoadFlatPrice = x.useAuxiliaryLoadFlatPrice ?? true
    rv.auxiliaryLoadFlatPrice = x.auxiliaryLoadFlatPrice ?? 1
    if (!rv.useAuxiliaryLoadFlatPrice) {
        const mkt = energyMarkets.find(m => m.kind === x.energyMarketKind)
        if (!mkt) {
            rv.useAuxiliaryLoadFlatPrice = true
            rv.energyMarket = null
        } else {
            rv.energyMarket = mkt
        }
    }
    rv.auxiliaryLoadPriceMultiYear = deserializeMultiYearVar(x.auxiliaryLoadPriceMultiYear)

    rv.hasConverter = x.hasConverter
    rv.converter = deserializeConverter(x.converter)

    rv.hasDeferment = x.hasDeferment ?? false
    rv.deferment.isExactDate = x.deferment?.isExactDate ?? false
    rv.deferment.period = x.deferment?.period ?? 1
    rv.deferment.periodUnit = x.deferment?.periodUnit ?? 0
    rv.deferment.startDate = x.deferment?.startDate !== undefined && x.deferment?.startDate !== '' ? deserializeDate(x.deferment?.startDate) : expectedDate
    return rv
}


function deserializeConverter(x?: ConverterDto): Inverter {
    if (!x) { return new Inverter() }
    let rv = new Inverter()
    rv.sizing = deserializeSizingVar(x.size)
    rv.cost = deserializeCostTable(x.cost)
    rv.omEscalator = deserializeMultiYearVar(x.omEscalator)
    rv.replacementEscalator = deserializeMultiYearVar(x.replacementEscalator)
    rv.efficiency = deserializeSensitivityVar(x.efficiency)
    rv.lifetime = deserializeSensitivityVar(x.lifetime)
    return rv
}


function deserializeResource(x?: ResourceDto): Resource {
    let rv = new Resource()
    if (x) {
        rv.kind = x.kind as ResourceKind
        rv.data = deserializeTimeSeries(x.data)
        rv.scale = deserializeSensitivityVar(x.scale)
    }
    return rv
}


function deserializeIncentive(x: IncentiveDto): Incentive {
    switch (x.kind) {
        case 'itc': {
            let rv = new InvestmentTaxCredit()
            rv.solar = x.solar
            rv.storage = x.storage
            rv.wind = x.wind
            if (x.eligiblePercent) rv.eligiblePercent = x.eligiblePercent
            if (x.itcPercent) rv.itcPercent = x.itcPercent
            return rv
        }
        case 'macrs': {
            let rv = new Macrs()
            rv.solar = x.solar
            rv.storage = x.storage
            rv.wind = x.wind
            if (x.eligiblePercent) rv.eligiblePercent = x.eligiblePercent
            if (x.marginalTaxPercent) rv.marginalTaxPercent = x.marginalTaxPercent
            return rv
        }
        case 'bonus': {
            let rv = new BonusDepreciation()
            rv.solar = x.solar
            rv.storage = x.storage
            rv.wind = x.wind
            if (x.eligiblePercent) rv.eligiblePercent = x.eligiblePercent
            if (x.marginalTaxPercent) rv.marginalTaxPercent = x.marginalTaxPercent
            if (x.bonusPercent) rv.bonusPercent = x.bonusPercent
            return rv
        }
        case 'prod': {
            let rv = new ProductionBased()
            rv.solar = x.solar
            rv.storage = x.storage
            rv.wind = x.wind
            if (x.credit) rv.credit = x.credit
            if (x.maxCredit) rv.maxCredit = x.maxCredit
            if (x.useDuration) rv.useDuration = x.useDuration
            if (x.duration) rv.duration = x.duration
            if (x.useCreditTaxPercent) rv.useCreditTaxPercent = x.useCreditTaxPercent
            if (x.creditTaxPercent) rv.creditTaxPercent = x.creditTaxPercent
            return rv
        }
        default:
            throw new Error()
    }
}


function deserializeTimeSeries(x?: TimeSeriesDto): TimeSeries {
    let rv = new TimeSeries()
    if (x?.data.length)
        rv.setData(x?.data, x?.year ?? 2007)
    return rv
}

function deserializeMultiYearTimeSeries(x?: MultiYearTimeSeriesDto): MultiYearTimeSeries {
    let rv = new MultiYearTimeSeries()
    if (x?.data.length)
        rv.setData(x?.data, x?.year ?? 2007, x.timeSeriesFileBaseName, x.projectYearsValid)
    return rv
}

export function deserializeSensitivityVar(dto: SensitivityVarDto | number): SensitivityVar {
    let rv = new SensitivityVar()
    if (typeof dto === 'number') {
        rv.values.splice(0, rv.values.length, new SensitivityItem({ value: dto }))
    } else {
        rv.values.splice(0, rv.values.length, ...dto.values.map(x => new SensitivityItem({ value: x })))
    }
    if (rv.values.length === 0) { rv.values.push(new SensitivityItem()) }
    return rv
}


function deserializeMultiYearVar(dto?: MultiYearVarDto): MultiYearVar {
    let rv = new MultiYearVar()
    if (!dto) return rv
    rv.enabled = dto.enabled
    rv.multiYearKind = dto.multiYearKind
    rv.linearValue = dto.linearValue
    rv.customValues.splice(0, rv.customValues.length,
        ...dto.customValues.map(x => new MultiYearItem({ year: x.year, value: x.value })))
    return rv
}


export function deserializeSizingVar(dto: SizingVarDto | number): Sizing {
    let rv = new Sizing()
    if (typeof dto === 'number') {
        rv.setFirstValue(dto)
    }
    else {
        rv.kind = dto.sizingKind as SizingKind
        const values = dto.values.map(x => new SizeItem({ value: x }))
        if (dto.sizingKind === SizingKind.absolute)
            rv.absoluteValues.splice(0, rv.absoluteValues.length, ...values)
        else if (dto.sizingKind === SizingKind.relative)
            rv.relativeValues.splice(0, rv.relativeValues.length, ...values)
    }
    return rv
}

function deserializeMonthlyVar(dto: MonthlyVarDto): MonthlyVar {
    let rv = new MonthlyVar()
    rv.values.splice(0, rv.values.length,
        ...dto.values.map((x, index) => new MonthlyVarItem(index, { value: x })))
    rv.average = dto.values.length > 0 ? dto.values[0] : 0
    return rv
}


function deserializeCostTable(x: CostTableDto): CostTable {
    let rv = new CostTable()
    rv.costTableType = x.costTableKind as CostTableType
    rv.simple = deserializeSimpleCostTable(x.simpleCostTable)
    rv.complex = deserializeComplexCostTable(x.complexCostTable)
    return rv
}


function deserializeSimpleCostTable(x?: SimpleCostTableDto): SimpleCostTable {
    if (!x) { return new SimpleCostTable() }
    let rv = new SimpleCostTable()

    const base = new CostSensitivityItem({ capital: x.capital, operating: x.operating, replacement: x.replacement, augmentation: x.augmentation })

    const n = Math.min(
        x.capitalSensitivity.values.length,
        x.operatingSensitivity.values.length,
        x.replacementSensitivity.values.length)
    rv.items.splice(0)
    rv.items.push(base)
    for (let i = 1; i < n; i++) {
        rv.items.push(new CostSensitivityItem({
            capital: x.capitalSensitivity.values[i] * base.capital,
            operating: x.operatingSensitivity.values[i] * base.operating,
            replacement: x.replacementSensitivity.values[i] * base.replacement,
            augmentation: x.replacementSensitivity.values[i] * base.augmentation,
        }))
    }
    return rv
}


export function deserializeComplexCostTable(dto?: ComplexCostTableDto): ComplexCostTable {
    if (!dto) { return new ComplexCostTable() }
    let rv = new ComplexCostTable()
    rv.directCapital.items.splice(0, rv.directCapital.items.length,
        ...dto.directCapital.map(x => new ComplexCostItem({ name: x.name, unit: x.units as CostUnit, value: x.value })))
    rv.indirectCapital.items.splice(0, rv.indirectCapital.items.length,
        ...dto.indirectCapital.map(x => new ComplexCostItem({ name: x.name, unit: x.units as CostUnit, value: x.value })))
    rv.operating.items.splice(0, rv.operating.items.length,
        ...dto.operating.map(x => new ComplexCostItem({ name: x.name, unit: x.units as CostUnit, value: x.value })))
    rv.replacement.items.splice(0, rv.replacement.items.length,
        ...dto.replacement.map(x => new ComplexCostItem({ name: x.name, unit: x.units as CostUnit, value: x.value })))
    return rv
}


function deserializeDate(x: string): Date {
    // translate back to user time zone from UTC
    const server = new Date(x)
    const offsetMs = server.getTimezoneOffset() * 60 * 1000
    return new Date(server.getTime() + offsetMs)
}

function deserializeRateSchedule(rate?: ScheduleRateDto[], schedule?: number[][]): RateSchedule {
    let rv = new RateSchedule()
    rv.schedule = schedule != null && schedule.length > 0 ? schedule : defaultSchedule()
    if (rate !== undefined && rate.length > 0) {
        rv.scheduleRates.splice(0, rv.scheduleRates.length,
            ...rate.map(x => new ScheduleRate({
                color: x.color,
                value: x.value,
            }))
        )
    }
    return rv
}

function maintainCompatibility(dto: InputsDto) {
    if (dto.version === undefined || dto.version < 2) {
        dto.solar.forEach(x => {
            x.solarKind = x.kind === undefined || x.kind === '' ? x.solarKind : x.kind === "custom" ? ComponentKind.custom : ComponentKind.homer;
            x.size.sizingKind = convertSizingKind(x.size)
            x.omEscalator.multiYearKind = convertMultiYearKind(x.omEscalator.multiYearKind, x.omEscalator.kind)
            x.degradation.multiYearKind = convertMultiYearKind(x.degradation.multiYearKind, x.degradation.kind)
            x.cost.costTableKind = convertCostTableKind(x.cost)
            if (x.converter) {
                x.converter.size.sizingKind = convertSizingKind(x.converter.size)
                x.converter.omEscalator.multiYearKind = convertMultiYearKind(x.converter.omEscalator.multiYearKind, x.converter.omEscalator.kind)
                x.converter.replacementEscalator.multiYearKind = convertMultiYearKind(x.converter.replacementEscalator.multiYearKind, x.converter.replacementEscalator.kind)
                x.converter.cost.costTableKind = convertCostTableKind(x.converter.cost)
            }
        })
        dto.wind.forEach(x => {
            //x.solarKind = x.kind !== undefined && x.kind === "custom" ? ComponentKind.custom : x.solarKind;
            x.size.sizingKind = convertSizingKind(x.size)
            x.customSizing.sizingKind = convertSizingKind(x.customSizing)
            x.omEscalator.multiYearKind = convertMultiYearKind(x.omEscalator.multiYearKind, x.omEscalator.kind)
            x.cost.costTableKind = convertCostTableKind(x.cost)
        })
        dto.storage.forEach(x => {
            //x.solarKind = x.kind !== undefined && x.kind === "custom" ? ComponentKind.custom : x.solarKind;
            x.size.sizingKind = convertSizingKind(x.size)
            if (x.converter !== undefined)
                x.converter.size.sizingKind = convertSizingKind(x.converter.size)
            x.omEscalator.multiYearKind = convertMultiYearKind(x.omEscalator.multiYearKind, x.omEscalator.kind)
            x.augmentationPriceDecline.multiYearKind = convertMultiYearKind(x.augmentationPriceDecline.multiYearKind, x.augmentationPriceDecline.kind)
            if (x.auxiliaryLoadPriceMultiYear !== undefined)
                x.auxiliaryLoadPriceMultiYear.multiYearKind = convertMultiYearKind(x.auxiliaryLoadPriceMultiYear.multiYearKind, x.auxiliaryLoadPriceMultiYear.kind)
            x.cost.costTableKind = convertCostTableKind(x.cost)
            if (x.converter) {
                x.converter.size.sizingKind = convertSizingKind(x.converter.size)
                x.converter.omEscalator.multiYearKind = convertMultiYearKind(x.converter.omEscalator.multiYearKind, x.converter.omEscalator.kind)
                x.converter.replacementEscalator.multiYearKind = convertMultiYearKind(x.converter.replacementEscalator.multiYearKind, x.converter.replacementEscalator.kind)
                x.converter.cost.costTableKind = convertCostTableKind(x.converter.cost)
            }
        })
        dto.energyMarkets.forEach(x => {
            x.priceEscalator.multiYearKind = convertMultiYearKind(x.priceEscalator.multiYearKind, x.priceEscalator.kind)
        })
        dto.contracts.forEach(x => {
            x.energyPriceEscalator.multiYearKind = convertMultiYearKind(x.energyPriceEscalator.multiYearKind, x.energyPriceEscalator.kind)
        })
        dto.capacityMarkets.forEach(x => {
            x.capacityPriceEscalator.multiYearKind = convertMultiYearKind(x.capacityPriceEscalator.multiYearKind, x.capacityPriceEscalator.kind)
            x.energyPriceEscalator.multiYearKind = convertMultiYearKind(x.energyPriceEscalator.multiYearKind, x.energyPriceEscalator.kind)
        })
        dto.incentives.forEach(x => {

        })
        if (dto.converter !== undefined) {
            dto.converter.size.sizingKind = convertSizingKind(dto.converter.size)
            dto.converter.omEscalator.multiYearKind = convertMultiYearKind(dto.converter.omEscalator.multiYearKind, dto.converter.omEscalator.kind)
            dto.converter.replacementEscalator.multiYearKind = convertMultiYearKind(dto.converter.replacementEscalator.multiYearKind, dto.converter.replacementEscalator.kind)
            dto.converter.cost.costTableKind = convertCostTableKind(dto.converter.cost)
        }
        dto.version = 1
    }
    function convertMultiYearKind(x: MultiYearKind, y: string | undefined) {
        return y === undefined || y === '' ? x : y === 'custom' ? MultiYearKind.custom : MultiYearKind.linear
    }
    function convertCostTableKind(x: CostTableDto) {
        return x.kind === undefined || x.kind === '' ? x.costTableKind : x.kind === 'complex' ? CostTableType.complex : CostTableType.simple
    }
    function convertSizingKind(x: SizingVarDto) {
        return x.kind === undefined || x.kind === '' ? x.sizingKind : x.kind === 'relative' ? SizingKind.relative : SizingKind.absolute
    }
}
