import React, {useEffect, useState} from 'react'
import {Button, Modal, Spinner} from 'react-bootstrap'
import YearSelector from 'components/helper/YearSelector'
import FilePicker from 'components/helper/FilePicker'
import api from 'services/api'
import TimeSeriesView from './TimeSeriesView'
import { TimeSeries } from '../project/model/TimeSeries'


interface TimeSeriesImportProps {
    onSelect?: (data: number[], year: number, fileName: string) => void
    label?: string
    disabled?: boolean
    visible?: boolean
    intlCulture?: string
    model?: TimeSeries
    showTimseriesViewer?: boolean
    timeSeriesViewerTitle?: string
}

const TimeSeriesImport = ({ onSelect, label, model, intlCulture = "en-US", showTimseriesViewer = false, disabled = false, visible = true, timeSeriesViewerTitle = '' }: TimeSeriesImportProps) => {
    const [show, setShow] = useState(false)
    const open = () => setShow(true)
    const close = () => setShow(false)

    const handleSelect = (data: number[], year: number, fileName: string) => {
        if (!showTimseriesViewer)
            close()
        onSelect?.(data, year, fileName)
    }

    return (<>
        {visible && <button className={`btn btn-file btn-primary pillButton`} disabled={disabled}
            onClick={open}>
            {label ?? 'Import...'}
        </button>}
        <TimeSeriesImportModal show={show} onSelect={handleSelect} onCancel={close} model={model} showTimseriesViewer={showTimseriesViewer} timeSeriesViewerTitle={timeSeriesViewerTitle} />
    </>)
}

export default TimeSeriesImport


interface TimeSeriesImportModalProps {
    show: boolean
    onSelect: (data: number[], year: number, fileName: string) => void
    onCancel: () => void
    intlCulture?: string
    model?: TimeSeries
    showTimseriesViewer?: boolean
    timeSeriesViewerTitle?: string
}

type State
    = { kind: 'blank' }
    | { kind: 'selected', source: string, content: string, year?: number }
    | { kind: 'importing', source: string, content: string, year?: number }
    | { kind: 'imported', source: string, content: string, year?: number }
    | { kind: 'error', source: string, message: string }

const TimeSeriesImportModal = ({ show, onSelect, onCancel, model, intlCulture = "en-US", showTimseriesViewer = false, timeSeriesViewerTitle = '' }: TimeSeriesImportModalProps) => {
    const [state, setState] = useState<State>({kind: 'blank'})
    useEffect(() => setState({kind: 'blank'}), [show])

    const handleSelect = (content: string, file: File) => {
        const source = file.name
        setState({kind: 'selected', source, content})
    }

    const handleImport = async () => {
        if (state.kind !== 'selected') return
        const {source, content, year} = state

        try {
            setState({kind: 'importing', source, content, year})
            const r = await api.import(content, year)
            if (!r.success) {
                console.error(r.message)
                setState({kind: 'error', source, message: 'Incorrect file format'})
                return
            }
            onSelect(r.data, r.year, state.source)
            setState({ kind: 'imported', source, content, year })
        } catch (error) {
            console.error(error)
            setState({kind: 'error', source, message: `Service unavailable`})
            return
        }
    }

    const handleSetYear = (x?: number) => {
        if (state.kind !== 'selected') return
        setState({...state, year: x})
    }

    return (
        <Modal show={show} onHide={onCancel}>
            <Modal.Header closeButton onHide={onCancel}>
                <Modal.Title>Import File</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex flex-row align-items-center'>
                    <FilePicker onSelect={handleSelect} label='Open...'/>
                    <div className='mx-2'/>
                    {(state.kind === 'selected' || state.kind === 'importing' || state.kind === 'error') &&
                        <div>File: <strong>{state.source}</strong></div>}
                </div>
                {(state.kind === 'selected' || state.kind === 'importing') &&
                    <div className='mt-2'>
                        <YearSelector label='Year (optional)' value={state.year}
                                      onValueChanged={handleSetYear} min={2000} max={2050}/>
                    </div>}
                {(state.kind != "selected" && state.kind != "importing" && model?.data != undefined && model.state == 'ready' && showTimseriesViewer) &&
                    <div className='mt-2'> {/*            <div className='col-6 pb-2 d-flex flex-column align-items-center'>*/}
                        <TimeSeriesView intlCulture={intlCulture} model={model?.data != undefined && model.data.length > 0 ? model : new TimeSeries()} chartTitle={timeSeriesViewerTitle} yAxisTitle={`kw`} />
                    </div>}
                {state.kind === 'error' &&
                    <div className='mt-2 text-danger'>Import error: {state.message}</div>}
            </Modal.Body>
            <Modal.Footer>
                <Spinner hidden={state.kind !== 'importing'} animation='border' size='sm' className='text-primary pillButton'/>
                <Button className='pillButton' onClick={onCancel} hidden={state.kind === 'imported'} variant='outline-primary'>Cancel</Button>
                <Button className='pillButton'  onClick={handleImport} hidden={state.kind !== 'selected'} disabled={state.kind !== 'selected'}>{(showTimseriesViewer ? "Import" : "Ok" )}</Button>
                <Button className='pillButton'  onClick={onCancel} hidden={state.kind === 'selected'} disabled={state.kind !== 'imported'}>{(showTimseriesViewer ? "Close" : "Ok")}</Button>
            </Modal.Footer>
        </Modal>
    )
}
