import {Service} from './Service'
import {OstDimension, OstResult, ResultTable} from 'components/project/results'
import {ResultDetails, ResultTimeSeries} from 'components/project/results-details'
import { ComplexCostTable, CostTable, SimpleCostTable } from 'components/project/model/CostTable'
import { DateFormatLabel, DecimalSeparator } from '../components/project/model'
import { Inverter } from '../components/project/model/Inverter'
import { ComplexCostTableDto, ConverterDto, CostTableDto, SensitivityVarDto, SimpleCostTableDto} from './inputs'
import { SystemSizerResultTable } from '../components/project/model/Contract'

export interface BaseProjectInfo {
    name: string
    author: string
    location: string
    phase: string
    type: string // Solar | Storage | Solar + Storage
    coupling: string // AC | DC | AC vs. DC
    solarSize?: number
    storageSize?: number
    irr?: number
    poi?: number
    dateCod: string
    dateNtp: string
    electricityMarket: string
    earlyAnalysis: boolean
    notes: string
    currency: string
    currencySymbol: string
    useCommaAsDecimalPoint: boolean
    applyShiftDays: boolean
    dateFormat: number
    isNewSystemConverter?: boolean | null
    locked?: boolean | null
    lockId?: string | null
}

export interface ProjectInfo extends BaseProjectInfo {
    id: string
    created: string
    updated: string
    locked?: boolean
    lockId?: string
    projectStatus: DBProjectStatus
}
export enum DBProjectStatus {
    Created,
    Updated,
    Calculating,
    Calculated,
    Cancelled
}
export interface UserStorageInfo {
    id: string
}

export interface UserData {
    email: string
    defaultCurrency: string
    defaultDecimalPoint: boolean
    defaultDateFormat: number
    showBaseYearAsYearZero: boolean
}

const service = new Service()
const api = service
export default api


export interface ImportResponse {
    success: boolean
    message: string
    data: number[]
    year: number
}

export interface UserEmailNotification {
    userEmail: string
}

export interface TableResponse {
    data: ResultTable
}
export interface SystemSizerTableResponse {
    data: SystemSizerResultTable
}

export interface DimensionResponse {
    data: OstDimension[]
}

export interface OstResponse {
    data: OstResult
}
export interface DetailsResponse {
    data: ResultDetails
}


export interface TimeSeriesResponse {
    data: ResultTimeSeries
}


export type StatusKind = 'changed' | 'calculating' | 'ready' | 'cancelled' /*| 'blank'*/

export interface StatusResponse {
    success: boolean
    message: string
    status: StatusKind
    progress?: number
    estimatedRemainingTime: string
}

export interface ReportResponse {
    succeded: boolean
    data?: Blob
    fileName?: string
}

export interface ExcelResponse {
    succeded: boolean
    data?: Blob
    fileName?: string
}

export interface ImportStorageRequest {
    content: string
}

export interface ImportWindRequest{
    content:string
}

export interface ImportStorageResponse {
    success: boolean
    message: string
    spec?: StorageSpec
}

export interface ImportWindResponse {
    success: boolean
    message: string
    spec?: WindTurbineSpec
}


export interface StorageCurveItem {
    x: number
    y: number
}

export interface StorageSpec {
    id: string
    name: string
    abbreviation: string
    nominalCapacity: number // kWh
    nominalPower: number // kW
    maximumCapacity: number // Ah
    rateConstant: number
    capacityRatio: number
    effectiveSeriesResistance: number
    nominalVoltage: number // V
    chargeCurrent: number // A
    dischargeCurrent: number // A
    roundtripEfficiency: number // %
    oneWayEfficiency: number // %
    rangeOfCharge: number // %
    minimumStateOfCharge: number // %
    estimatedLifetimeThroughput: number // kWh
    lifetimeCurve: StorageCurveItem[]
    chemistry: string
    manufacturer: string
    requiresOneMinuteTimestep: boolean
    fittedD0: number
    fittedD1: number
    fittedD2: number
    fittedA: number
    fittedBeta: number
    capacityDegradationLimitForFitCyclelifetimeModel: number
    capacityDegradationLimitForFitLifetimeTemperatureModel: number
    arrheniusDegredationB: number
    arrheniusDegredationD: number
    //max discharge current
    //mode: string
    //duration: number
    notes: string
    stringsize: number
    initialStateOfCharge: number
    ///capacityCurve: StorageCurveItem[]
    lifetimeLimit: number
    ///useLifetimeCurve: boolean
    ///useDedicatedConverter: boolean
    capacityCurve: StorageCurveItem[]
    useLifetimeCurve: boolean
    useDedicatedConverter: boolean
    applyMaxDischargeCurrent: boolean
    applyMaxChargeCurrent: boolean
    applyMaxChargeRate: boolean
    maxDischargeCurrent: number
    maxChargeCurrent: number
    maxChargeRate: number
    otherLosses: number
    additiveEndOfLifeCalculation: boolean
    degradationUsesNominalSoc: boolean
    ignoreCapacityVsTemperatureInModel: boolean
    capacityTemperatureCurve: StorageCurveItem[]
    ignoreTemperatureEffectOnLifetime: boolean
    simpleLifetimeInYears: number
    stringSize: number
    lifetimeTemperatureCurve: StorageCurveItem[]
    maximumOperatingTemperature: number
    minimumOperatingTemperature: number
    mass: number
    fixedTemperature: number
    thermalH: number
    thermalCSpecific: number
    augmentationDegradationLimitSensitivity: SensitivityVarDto
    augmentationCost: number
    //AugmentationPriceDecline: 
    dedicatedConverter: ConverterDto
    applyMinimumLifetime: boolean
    minimumLifetime: number
    isAc: boolean
    useFixedTemperature: boolean
    useComplexCost: boolean
    costTable: CostTableDto
    simpleCostTable: SimpleCostTableDto
    complexCostTable: ComplexCostTableDto
    //SimpleCostTable:
}

export interface SystemSizerLimits {
    useSolar: boolean
    useWind: boolean
    useStorage: boolean
    solarMaxSize: number
    solarMinSize: number
    windRatingSize: number
    windMaxSize: number
    windMinSize: number
    storageMaxSize: number
    storageMinSize: number
}

export interface WindTurbinePowerCurveItem {
    x: number // speed (m/s)
    y: number // power (kW)
}

export interface WindTurbineSpec {
    name: string
    hubHeight: number
    powerCurve: WindTurbinePowerCurveItem[]
    useComplexCost: boolean
    costTable: CostTableDto
    simpleCostTable: SimpleCostTableDto
    availabilityLosses: number
    turbinePerformanceLosses: number
    environmentalLosses: number
    wakeEffectsLosses: number
    electricalLosses: number
    curtailmentLosses: number
    otherLosses: number
    altitude: number
    anemometerHeight: number
    lifeTime:number
}

export interface ComponentOperationResponse {
    success: boolean
    message: string
    component: LibraryComponent
}

export interface LibraryComponent {
    type: string
    name: string
}
